.mpublisherImages {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-top: 20px;
    ;

    .image-wrapper {
        &.active {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 40%;
                left: 43%;
                width: 30px;
                height: 30px;
                background-image: url(../../../../assets/images/tick.svg);
                background-size: cover;
                background-position: center;
            }

            img {
                filter: brightness(0.4);
            }
        }
    }

    img {
        width: 100%;
        aspect-ratio: 16/9;
    }
}

.mpublisherContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;

    .seeMore {
        margin: 0 0 0 auto !important;

        a {
            color: #A76089;
            font-weight: bold;

            &:hover {
                color: #A76089 !important;
                text-decoration: underline !important;
                cursor: pointer;
            }
        }
    }

    .mpublisherButtons {
        margin: 30px 0 0 auto;
    }

    .title {
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: bold;

        svg {
            path {
                fill: white;
            }
        }
    }
}
.order-showrooms-main {
    background-color: rgba(0, 0, 0, 0.917);
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    // transition: 0.3s ease-in-out;
    padding-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;

}
.designtool-template{
   min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.order-container {
    height: 400px;
    overflow: "hidden";
    transition: 0;
}

.visible-show {
    height: 400px;
}

.text-container-button {
    visibility: visible;
    opacity: 1;
    max-height: 30px;
    // transition: max-height 0.6s ease-in-out;
    position: fixed;
    top: -15px;
    right: 3rem;
    z-index: 2;
    svg {
        // transform: rotate(-90deg);
        width: 20px;

        g,
        path {
            fill: rgba(255, 255, 255, 0.739);
        }
    }
}

.reorder-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 20%;
    position: absolute;

    p {
        color: rgba(255, 255, 255, 0.914);
        font-size: 22px;
    }
}

.reorder-close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 7px;
    background-color: rgba(58, 57, 57, 0.5);
    border-radius: 50%;
    //   top: -10% !important;
    bottom: 110%;

    &:hover {
        background-color: rgba(58, 57, 57, 0.808);
    }

    svg {
        transform: scale(0.8);
    }
}

.reorder-item {
    padding: 5px;
    border-radius: 50%;
    color: white;
    opacity: 0.8;
    margin: 10px 5px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 550;
}

.activeRowItem {
    color: #ec7600;
}

.rowitems-container {
    position: absolute;
    width: 100%;
    justify-content: center;
    align-items: center;
    bottom: 28%;

    span {
        color: rgba(255, 255, 255, 0.888);
        margin-top: 3px;
    }
}

.hide-text {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: max-height 0.6s ease-in-out;
}
.modals-backgrounds{
    position: fixed;
    top: 65px;
    right: 50px;
    padding: 2rem;
    border-radius: 10px;
    background-color: rgb(44, 44, 44);
    z-index: 999;
    width: 350px;
    .mpublisherBtn{
        width: 100%;
        display: block;
        margin: 20px 0 0 auto;
        span{
            color: #9E6E8A;
            font-weight: bold;
        }
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar {
        width: 6px;
        height: 4px;
        background-color: transparent;
      }
    &::-webkit-scrollbar-thumb {
        background-color: #a7abb154;
    }
    button{
        color: white;
        opacity: 0.7;
        text-align: left;
        max-width: max-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 15px;
        margin-bottom: 1rem;
        &:hover{
            opacity: 1;
        }
    }
    .images-bg{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 10px;
        height: 400px;
        overflow-y: scroll;
        .bgHolder{
            opacity: 0.8;
            border: 1px solid transparent;
            cursor: pointer;
            &:hover{
                opacity: 1;
            }
        }
        .selected{
            opacity: 1;
            border: 1px solid rgba(255, 255, 255, 0.136);
            border-radius: 5px;


        }
   img{
    aspect-ratio: 16/9;
    width: 100%;
    border-radius: 3px;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
   }
    }
}
.item-center-1{
    padding-top: 4rem;
}
.item-center-2{
    padding-top: 5rem;
}
.presentation-mode-effect
{
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 99;
    opacity: 0.6;
    &:hover{
        .presentation-tooltip{
            visibility: visible;
        }
    }
    .presentation-tooltip{
        color: white;
        width: max-content;
        padding: 5px 20px;
        background-color: rgba(25, 25, 25, 0.352);
        border-radius: 5px;
        backdrop-filter: blur(30px);
        text-align: left;
        position: absolute;
        top: 2px;
        left: 50px;
        z-index: 9999;
        visibility: hidden;
    }
    &:hover{
        opacity: 1;
    }
    svg{
        font-size: 35px;
        color: gray;
    }
}
@media (max-height: 600px) {
    .reorder-container{
        top: 17%;
    }
    .rowitems-container{
        bottom: 22%;
    }
}
@media (max-height: 720px) {
    .reorder-container{
        p{
            font-size: 20px;
        }
    }
    .modals-backgrounds{
        padding: 1.5rem 2rem;
        overflow-y: scroll;
        height: 88vh;
    }
}
@media (min-width: 2000px) {
    .reorder-container p{
        transform: scale(1.2);
    }
    .reorder-container{
        top: 32%;
    }
    .reorder-item{
        margin: 10px;
        font-size: 28px;
    }
}
@media (min-height: 1440px) and (min-width: 2560px) {
    .reorder-container p{
        transform: scale(1.5);
        margin-top: 15px;
    }
    .reorder-item{
        margin: 10px 15px;
        font-size: 30px;
    }
    .text-container-button{
        top: 85px;
        svg{
            width: 30px;
            height: 30px;
        }
    }
    .modals-backgrounds{
        top: 60px;
        right: 35px;
        zoom: 1.2;
    }
}
@media (min-height: 2160px) and (min-width: 3840px) {
    .reorder-container p{
        transform: scale(2.2);
    }
    .reorder-close{
        bottom: 195%;
    }
    .reorder-item{
        margin: 10px 20px;
        font-size: 45px;
    }
    .rowitems-container{
        bottom: 34%;
    }
    .text-container-button{
        top: 115px;
        svg{
            width: 45px;
        }
    }
    .modals-backgrounds{
        top: 55px;
        right: 25px;
        zoom: 1.8;
    }
}
@media (min-width: 4000px) {
    .text-container-button {
        svg{
          width: 60px;
          height: 60px;
        }
    }
    .reorder-container p{
        transform: scale(3.2);
    }
    .reorder-item{
        font-size: 60px;
    }
    .modals-backgrounds{
        top: 45px;
        right: 20px;
        zoom: 2.4;
    }
}
.single-template{
    width: 65% !important;
    .main-description{
        margin: 5px 0 !important;
    }
}
.preview-template{
    .single-template-12, .single-template-13, .single-template-14{
       .content{
        margin-bottom: 40px !important;
       }
    } 
}
.single-template-13{
    width: 75% !important;
    .template-single-card{
        display: flex;
        align-items: flex-start;
        height: 100%;
        .image{
            width: 80%;
        }
        .textcontainer{
            width: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 2rem;
            height: 100%;
            p{
                word-break: break-word;
            }
        }
    }
}
.single-template-14{
    width: 70% !important;
    .content{
        width: 90%;
        margin: 0 auto;
    }
    .mainplaybtn{
        margin: 25% auto !important;
    }
}
.single-template-15{
    width: 55% !important;
    .cards{
        overflow: visible;
    }
    .mainplaybtn{
        margin: 25% auto !important;
    }
}

.rpv-core__viewer{
    .rpv-zoom__popover-target-arrow{
        display: none;
    }
    .rpv-core__minimal-button, .rpv-zoom__popover-target-scale{
      opacity: 0.5;
      &:hover{
        opacity: 1;
      }
    }
    .rpv-default-layout__toolbar{
    .rpv-toolbar__left,.rpv-toolbar__right{
        display: none;
    }
    }
    .rpv-core__popover-body{
        background-color: rgba(15, 15, 15, 0.552);
        backdrop-filter: blur(30px);
        *{
            color: white;
        }
    }
    .rpv-core__arrow{
       display: none;
    }
  }
  .vertical-template{
   .containerSlider {
        overflow: hidden !important;
        max-height: 70vh !important;
    }
    .maincontent{
          max-width: 80%;
          margin: 0 auto;
          padding-top: 4vh;
      }
    .cards {
        flex-direction: column !important;
        justify-content: center;
        align-items: flex-start !important;
    }
    #right,#left{
        transform: rotate(90deg) !important;
        top: 51% !important;
    }
    #left{
        left: auto !important;
        right: -84px !important;
        top: 42% !important;
    }
    .visibilityItem{
        visibility: visible;
        transition: 0;
      }
      .hiddenItem{
       visibility: hidden;
       transition: 0 ;
      
      }
  }
  .vertical-template-preview{
    background: transparent !important;
    box-shadow: none !important;
  }
  .designtool-presenting{
    .template-single-card{
        &:hover{
            border: 7px solid #ec7600c6;
            transform: translateY(0px);
        }
    }
    .template-single-card{
        &:hover{
            .doc-container::after,.documents-container::after{
                visibility: visible;
            }
        }
    }
}
.active-presentation{
  svg{
      color: #ec7600;
  }
}