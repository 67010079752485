.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  a,
  button {
    cursor: pointer;
    padding: 8px 20px;
    color: white;
    opacity: 0.8;
    border-bottom: 1px solid transparent;
    font-size: 18px;
    margin: 0 10px;
    max-width: max-content;
    &:hover,
    &.selected {
      opacity: 1;
      border-color: white;
    }
  }
}

@media (max-width: 1200px) {
  .container {
    a {
      font-size: 16px;
      padding: 5px 20px;
    }
  }
}

@media (max-width: 991px) {
  .container {
    a {
      font-size: 15px;
      padding: 5px 15px;
    }
  }
}


@media (max-width: 575px) {
  .container {
    a {
      font-size: 14px;
      padding: 3px;
    }
  }
}