.menu-container {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out;
  background-color: #050505;
  z-index: 100;
}

.toggled {
  transform: translateY(0);
}

.left-container {
  padding: 0;
  margin: 0;
  margin-left: 50px;
  width: 20vw;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  p {
    text-align: left;
    font: normal normal bold 23px/27px Raleway;
    letter-spacing: 0px;
    color: #edeeef;
    margin-bottom: 15px;
  }
  .orange-p {
    text-align: left;
    font: normal normal normal 23px/27px Raleway;
  }
}
.content-container {
  padding: 0;
  margin: 0;
  width: 60vw;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  padding-bottom: 40px;
}
.right-container {
  padding: 0;
  margin: 0;
  margin-right: 50px;
  width: 20vw;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.toggle-button {
  position: absolute;
  top: 0;
  right: 200px;
  z-index: 90;
}

.close-button {
  position: absolute;
  bottom: 0;
  right: 300px;
  transition: bottom 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: rotate(180deg);
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 15px solid #ec7600;
  z-index: 2;
}

.push-top {
  transform: rotate(0);
  bottom: -15px;
}

.content-item {
  text-align: left;
  font: normal normal 800 16px/19px Raleway;
  letter-spacing: 0px;
  opacity: 1;
  color: #707070;
  cursor: pointer;
}

.selected {
  color: white;
}

@media screen and (min-height: 730px) {
  .menu-container{
    height: 70px;
  }
}

@media screen and (min-width: 3000px) {
  .push-top{
    bottom: -30px;
  }
  .close-button{
    border-left-width: 30px;
    border-right-width: 30px;
    border-top-width: 32px;
  }
  .menu-container{
    height: 115px;

  }
}

@media screen and (min-width: 4000px) {
  .push-top{
    bottom: -45px;
  }
  .close-button{
    border-left-width: 45px;
    border-right-width: 45px;
    border-top-width: 47px;
  }
  .menu-container{
    height: 130px;
  }
}

