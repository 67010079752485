.main-shared-text {
    width: 100%;
    h1,
    p {
        color: rgb(255, 255, 255);
        font-size: 24px;
        font-weight: 400;
    }

    h3 {
        margin: 1rem 0;
        line-height: 1.4rem;

        p {
            color: rgba(255, 255, 255, 0.79);
            width: 100%;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.4rem;
        }

        span {
            color: #cccccc;
            font-size: 14px;
        }
    }

    .description p {
        // font-size: 17px;
        font-size: 1.0625em;
    }
    .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
        color: rgba(255, 255, 255, 0.88);
        content: attr(data-mce-placeholder);
        position: absolute;
        font-size: 14px;
    }

    @media screen and (max-width: 1024px) {
        p {
            font-size: 1.8em;
        }
        h3 {
            p {
                font-size: 16px;
            }
        }
    }

    @media screen and (max-height: 650px) {
        h3 {
            margin: 0.3rem 0;
        }
    }

    @media screen and (max-height: 724px) {
        h3 {
            margin: 0.5em 0;
            font-size: 12px;

            p {
                font-size: 16px;
                line-height: 1.3rem;
            }

            span {
                font-size: 14px;
            }
        }
        .description p {
            font-size: 15px;
        }
    }
    @media (min-width: 2000px) {
        p {
            font-size: 3.5em;
        }
        h3 {
            margin: 1.5rem 0;

            p {
                font-size: 24px;
                line-height: 2.5rem;

                span {
                    font-size: 23px !important;
                }
            }
        }
    }

    @media screen and (min-width: 2560px) {
        p {
            font-size: 4em;
        }

        h3 {
            margin: 1.5rem 0;

            p {
                font-size: 22px;
                line-height: 2.5rem;

                span {
                    font-size: 23px !important;
                }
            }
        }
        .description p {
            font-size: 21px;
        }
    }

    @media screen and (min-width: 3000px) {
        p {
            font-size: 4em;
        }

        h3 {
            margin: 2.5rem 0;

            p {
                font-size: 35px;
                line-height: 3.5rem;

                span {
                    font-size: 36px !important;
                }
            }
        }
        .description p {
            font-size: 34px;
        }
    }

    @media screen and (min-width: 4000px) {
        p {
            font-size: 5em;
        }

        h3 {
            margin: 3.5rem 0;

            p {
                font-size: 49px;
                line-height: 4.5rem;

                span {
                    font-size: 50px !important;
                }
            }
        }
        .description p {
            font-size: 48px;
        }
    }
}

.bright-mode {
    .main-shared-text {
        h1,
        p {
            color: black;
        }
        h3 {
            p {
                color: black;
            }
            span {
                color: black;
            }
        }
    }
}
.main-shared-text #main-heading[data-mce-placeholder]:not(.mce-visualblocks)::before {
    font-size: 22px;
}
.showroom-link {
    border-bottom: 1px solid rgba(255, 255, 255, 0.396);
    max-width: max-content;
    padding: 2px;
    cursor: pointer;
    opacity: 0.8;
    padding-top: 1rem;
    &:hover {
        opacity: 1;
    }
}
