
  .magazine{
    background-color: #1a1a1a !important;
  }
  
  @media screen and (max-width: 768px) {
    .App, .tox-tinymce{
      display: unset;
    }
    :global(.tox.tox-tinymce-inline, .tox-editor-container){
      display: none;
    }
  }
  
  
  :global(.magazine .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    color: white;
  }
  
  :global(.magazine  [id^=main-heading] p){
    text-align: left;
    color: white;
    max-width:800px ;
    word-break: break-word;
    white-space: normal;
    font-family: Raleway;
    font-size: 47px;
    font-weight: 700 !important;
    line-height: 63px;
    letter-spacing: 0em;
  }
  :global(.magazine  [id^=main-heading] em),:global(.magazine  [id^=main-heading] span){
    font-weight: 700;
  }
  
  :global(.magazine [id^=main-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    font-family: Raleway;
    font-size: 47px;
    font-weight: 700;
    line-height: 63px;
    letter-spacing: 0em;
  }
  
  :global(.magazine  [id^=main-heading-multi-page-11-page-8] p){
    text-align: left;
    color: white;
    max-width:400px ;
    word-break: break-word;
    white-space: normal;
    font-family: Raleway;
    font-size: 30px ;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
  }
  
  
  :global(.magazine [id^=main-heading-multi-page-11-page-8].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    font-family: Raleway;
    font-size: 30px ;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
  }
  
  
  /* main-heading-multi-page-11-page-8 */
  
  
  :global(.magazine [id^=main-heading-page-8].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    font: normal normal bold 47px/63px Raleway;
    width:100%;
    text-align: center;
  }
  
  :global(.magazine  [id^=main-heading-page-8] p){
    text-align: center;
    color: white;
    font: normal normal bold 47px/63px  Raleway;
    width:100%;
    min-width:100% ;
    max-width:100% ;
    word-break: break-word;
    white-space: normal;
  }
  
  :global(.magazine [id^=main-heading-multi-page-11-page-868].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
  :global(.magazine [id^=main-heading-multi-page-11-page-868] p),
  :global(.magazine [id^=main-sub-heading-multi-page-11-page-3-73].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
  :global(.magazine [id^=main-sub-heading-multi-page-11-page-3-73] p)
  {
    margin-bottom: -25px!important;
  }
  
  :global(.magazine [id^=main-heading-page-878].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
  :global(.magazine [id^=main-heading-page-878] p)
  {
    text-align: left;
  }
  
  :global(.magazine  [id^=main-heading-page-8] span),:global(.magazine  [id^=main-heading-page-8] em){
    font-weight: bold;
    font-size: 47px;
    line-height: 63px;
  }
  
  :global(.magazine [id^=main-heading-page-11].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    text-align: left;
    font-family: Raleway;
    font-size: 52px ;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 0em;
  }
  
  :global(.magazine  [id^=main-heading-page-11] p){
    text-align: left;
    font-family: Raleway;
    font-size: 52px ;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 0em;
  }
  :global(.magazine  [id^=main-heading-page-11] em),:global(.magazine  [id^=main-heading-page-11] span){
    font-size: 52px ;
    font-weight: 700;
    line-height: 65px;
  }
  
  :global(.magazine [id^=main-heading-page-6]){
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  :global(.magazine [id^=main-heading-page-6].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    width: 100%;
    text-align: center;
  }
  
  :global(.magazine  [id^=main-heading-page-6] p){
    width: 100%;
    font-weight: bold;
    text-align: center;
  }
  :global(.magazine  [id^=main-heading-page-6] em), :global(.magazine  [id^=main-heading-page-6] span){
    font-weight: bold;
  }
  
  
  :global(.magazine #main-heading-type-4.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    font: normal normal bold 80px/91px Raleway;
    width:100%;
    max-width:100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  :global(.magazine #main-heading-type-4 p ){
    font: normal normal bold 80px/91px Raleway;
    text-align: center;
    width:100%;
    max-width:100%;
  }
  :global(.magazine #main-heading-type-4 span),
  :global(.magazine #main-heading-type-4 em){
    font-weight: bold;
    font-size: 80px;
    font-family: Raleway;
    line-height: 91px;
  }
  
  
  :global(.magazine #main-heading-400.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    font: normal normal normal 80px/91px Impact;
    width:100%;
    max-width:100%;
    text-align: center;
    display: flex;
    justify-content: center;
    word-break: break-word;
    white-space: normal;
  }
  :global(.magazine #main-heading-400 p ){
    font: normal normal normal 80px/91px Impact;
    text-align: center;
    width:100%;
    max-width:100%;
    word-break: break-word;
    white-space: normal;
  }
  :global(.magazine [id^=main-sub-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    color: white;
    font-family: Raleway;
    font-size: 25px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
  }
  :global(.magazine  [id^=main-sub-heading] p), :global(.magazine  [id^=main-sub-heading] span), :global(.magazine  [id^=main-sub-heading] em){
    text-align: left;
    color: white;
    font-family: Raleway;
    font-size: 25px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    word-break: break-word;
    white-space: normal;
  }
  
  
  /* main-sub-heading-multi-page-11-page-3 */
  :global(.magazine [id^=main-sub-heading-page-3]){
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  :global(.magazine [id^=main-sub-heading-page-3].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    /* padding-left: 10px; */
    width:100% ;
    text-align: center;
    white-space: normal;
    display:flex;
    flex-direction: column;
    align-items: center;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
  }
  :global(.magazine  [id^=main-sub-heading-page-3] p){
    /* padding-left: 10px; */
    text-align: center;
    /* display:flex;
    flex-direction: column;
    align-items: center; */
    max-width:90% ;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
  }
  
  :global(.magazine [id^=main-sub-heading-multi-page-11-page-3].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    /* padding-left: 10px; */
    width:100% ;
    text-align: left;
    white-space: normal;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    color:#D0CFCF;
  
  }
  :global(.magazine  [id^=main-sub-heading-multi-page-11-page-3] p){
    /* padding-left: 10px; */
    text-align: left;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    max-width:100% ;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    color:#D0CFCF;
  }
  
  :global(.magazine [id^=main-sub-heading2].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    /* padding-left: 10px; */
    width:100% ;
    text-align: center;
    white-space: normal;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
  }
  
  :global(.magazine  [id^=main-sub-heading2] ){
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    min-height: 70px;
  }
  :global(.magazine  [id^=main-sub-heading2] p), :global(.magazine  [id^=main-sub-heading2] em), :global(.magazine  [id^=main-sub-heading2] span){
    text-align: left;
    max-width:99% ;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
  }
  
  
  :global(.magazine [id^=main-sub-heading2-page-11].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  :global(.magazine  [id^=main-sub-heading2-page-11] p),:global(.magazine  [id^=main-sub-heading2-page-11] span),:global(.magazine  [id^=main-sub-heading2-page-11] em){
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
  }
  
  :global(.magazine [id^=main-sub-heading2-page-9].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    /* height: auto; */
    min-height:70px ;
    height: 100px;
    max-height: 300px;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  :global(.magazine  [id^=main-sub-heading2-page-9] p), :global(.magazine  [id^=main-sub-heading2-page-9] span), :global(.magazine  [id^=main-sub-heading2-page-9] em){
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px !important;
  }
  
  /* main-sub-heading2-page-7 */
  
  
  :global(.magazine [id^=main-sub-heading2-page-7].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
  
    color:#C9C0C0;
    font-family: Raleway;
    font-size: 19px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  :global(.magazine  [id^=main-sub-heading2-page-7] p),:global(.magazine  [id^=main-sub-heading2-page-7] span),:global(.magazine  [id^=main-sub-heading2-page-7] em){
    color:#C9C0C0;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  
  
  :global(.magazine [id^=description].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    font: normal normal normal 16px/28px Raleway;
    color:gray;
  }
  :global(.magazine  [id^=description] p){
    text-align: left;
    font: normal normal normal 16px/28px Raleway;
    color:gray;
    word-break: break-word;
    white-space: normal;
  }
  :global(.magazine [id^=description-single-4].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    font: normal normal normal 16px/28px Raleway;
    width:100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    opacity: 0.72;
    padding-left: 50px;
    padding-right: 50px;
  
  }
  
  
  
  :global(.magazine  [id^=description-single-4] p){
    margin: 0;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    font: normal normal normal 16px/28px Raleway;
    color: #FFFFFF;
    opacity: 0.72;
    word-break: break-word;
    white-space: normal;
  }
  :global(.magazine  [id^=description-single-4] span), :global(.magazine  [id^=description-single-4] em){
    white-space: normal;
    font-size: 16px;
    line-height: 28px;
    font-weight: normal;
  }
  
  :global(.tox.tox-tinymce-inline .tox-editor-header){
    background-color:transparent !important;
  }
  
  .magazine [id^=main-sub-heading2] p {
    width: 435px !important;
  }
  
  :global(.magazine [id^=main-sub-heading2].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ){
    font: normal normal normal 16px/25px Raleway
  }
  
  :global(.magazine [id^=main-sub-heading2] p ){
    font: normal normal normal 16px/25px Raleway
  }
  :global(.magazine [id^=main-sub-heading2] span ),:global(.magazine [id^=main-sub-heading2] em ){
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
  }
  
  :global(.tox .tox-tbtn--bespoke) {
    background: none !important;
  }
  
  
  @media screen and (max-width: 1650px) {
    :global(.magazine #main-heading-400.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine #main-heading-400 p )
    {
      font: normal normal normal 65px/76px Impact;
    }
  
    :global(.magazine [id^=main-sub-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
    :global(.magazine  [id^=main-sub-heading] p)
    {
      font: normal normal normal 21px/26px Raleway;
    }
  
    :global(.magazine [id^=main-sub-heading2-page-11-71].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
    :global(.magazine  [id^=main-sub-heading2-page-11-71] p)
    {
      font: normal normal normal 16px/25px Raleway;
    }
  
    :global(.magazine  [id^=main-sub-heading] span),  :global(.magazine  [id^=main-sub-heading] em){
      font-size: 19px;
      line-height: 26px;
      font-weight: normal;
    }
    :global(.magazine #main-heading-type-4.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine #main-heading-type-4 p )
    {
      font: normal normal bold 60px/91px Raleway;
    }
    :global(.magazine #main-heading-type-4 span),
    :global(.magazine #main-heading-type-4 em){
      font-weight: bold;
      font-size: 60px;
      font-family: Raleway;
    }
    :global(.magazine [id^=main-heading-page-11].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      font-size: 45px;
    }
    :global(.magazine [id^=main-heading-page-8].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine [id^=main-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine [id^=main-heading] p)
    {
      font: normal normal bold 42px/63px Raleway;
    }
  
    :global(.magazine [id^=main-heading] em),   :global(.magazine [id^=main-heading] span){
      font-size: 42px;
      line-height: 63px;
      font-weight: bold;
    }
  
    :global(.magazine [id^=main-sub-heading2-32] p),
    :global(.magazine [id^=main-sub-heading2-22] p),
    :global(.magazine [id^=main-sub-heading2-12] p),
    :global(.magazine [id^=main-sub-heading2-02] p),
    :global(.magazine [id^=main-sub-heading2-42] p),
    :global(.magazine [id^=main-sub-heading2-52] p)
    {
      font: normal normal normal 16px/25px Raleway;
    }
    :global(.magazine [id^=main-sub-heading2-32] span), :global(.magazine [id^=main-sub-heading2-32] em),
    :global(.magazine [id^=main-sub-heading2-22] span), :global(.magazine [id^=main-sub-heading2-22] em),
    :global(.magazine [id^=main-sub-heading2-12] span), :global(.magazine [id^=main-sub-heading2-12] em){
      font-size: 16px;
      font-weight: normal;
      line-height: 25px;
    }
    :global(.magazine [id^=main-sub-heading2-32].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
    :global(.magazine [id^=main-sub-heading2-22].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
    :global(.magazine [id^=main-sub-heading2-12].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
    :global(.magazine [id^=main-sub-heading2-02].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
    :global(.magazine [id^=main-sub-heading2-42].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
    :global(.magazine [id^=main-sub-heading2-52].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before )
    {
      font: normal normal normal 16px/25px Raleway;
    }
  }
  @media screen and (max-width: 1550px) {
    :global(.magazine #main-heading-400.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine #main-heading-400 p )
    {
      font: normal normal normal 57px/68px Impact;
    }
  
    :global(.magazine [id^=main-heading-page-8].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine [id^=main-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      font: normal normal bold 36px/63px Raleway;
    }
    :global(.magazine  [id^=main-heading] p){
      font: normal normal bold 36px/63px Raleway;
    }
    :global(.magazine  [id^=main-heading] em),   :global(.magazine  [id^=main-heading] span){
      font-size: 38px;
      line-height: 63px;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 1400px) {
    :global(.magazine #main-heading-400.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before)
    :global(.magazine #main-heading-400 p )
    {
      font: normal normal normal 65px/76px Impact;
    }
  
    :global(.magazine  [id^=main-sub-heading] p){
      font: normal normal normal 21px/24px Raleway;
    }
    :global(.magazine  [id^=main-sub-heading] span),   :global(.magazine  [id^=main-sub-heading] em){
      font-size: 21px;
      line-height: 24px;
      font-weight: normal;
    }
    :global(.magazine [id^=main-heading-page-11].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      font-size: 40px;
    }
    :global(.magazine  [id^=main-heading] p),
    :global(.magazine [id^=main-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before)
    {
      font: normal normal bold 34px/49px Raleway;
    }
    :global(.magazine  [id^=main-heading] em),:global(.magazine  [id^=main-heading] span){
      font-size: 34px;
      line-height: 49px;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 1370px) {
    :global(.magazine #main-heading-400.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before) {
      font: normal normal normal 60px/71px Impact;
    }
  
    :global(.magazine #main-heading-400 p) {
      font: normal normal normal 60px/71px Impact !important;
    }
  
    :global(.magazine [id^=main-sub-heading] p),
    :global(.magazine [id^=main-sub-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before )
    {
      font: normal normal normal 18px/21px Raleway;
    }
    :global(.magazine [id^=main-sub-heading] span),  :global(.magazine [id^=main-sub-heading] em) {
      font-size: 18px;
      line-height: 21px;
      font-weight: normal;
    }
  
    :global(.magazine #main-heading-type-4.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)) {
      font: normal normal normal 65px/76px Impact;
    }
  
    :global(.magazine #main-heading-type-4.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine #main-heading-type-4 p )
    {
      font: normal normal bold 60px/76px Raleway;
    }
    :global(.magazine #main-heading-type-4 span ), :global(.magazine #main-heading-type-4 em ){
      font-family: Raleway, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      font-size: 60px;
      line-height: 76px;
      font-weight: bold;
    }
    :global(.magazine [id^=main-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine [id^=main-heading] p)
   {
      font: normal normal bold 33px/49px Raleway
    }
    :global(.magazine [id^=main-heading] span),  :global(.magazine [id^=main-heading] em){
      font-size: 33px;
      line-height: 49px;
      font-weight: bold;
    }
  
    :global(.magazine [id^=main-sub-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)) {
      font: normal normal normal 22px/25px Raleway;
    }
  
    :global(.magazine [id^=main-sub-heading2] span ),:global(.magazine [id^=main-sub-heading2] em ){
      font-size: 14px;
      line-height: 25px;
      font-weight: normal;
    }
    :global(.magazine [id^=main-sub-heading2].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
    :global(.magazine [id^=main-sub-heading2] p )
    {
      font: normal normal normal 14px/25px Raleway
    }
  
  }
  
  
  @media screen and (max-width: 1280px) and (max-height: 800px) {
    :global(.magazine [id^=main-sub-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
    :global(.magazine [id^=main-sub-heading] p)
    {
      font: normal normal normal 16px/26px Raleway;
    }
    :global(.magazine [id^=main-sub-heading] span),:global(.magazine [id^=main-sub-heading] em){
      font-size: 16px;
      line-height: 26px;
      font-weight: normal;
    }
  }
  
  @media screen and (min-width:2500px) {
    :global(.magazine [id^=description] p),
    :global(.magazine [id^=description].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      font: normal normal normal 22px/28px Raleway;
    }
    :global(.magazine [id^=description] span),:global(.magazine [id^=description] em){
      font-size: 22px;
      line-height: 28px;
      font-weight: normal;
    }
    :global(.magazine [id^=main-sub-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before)
   {
      font-size: 26px;
      line-height: 30px;
    }
  }
  
  @media screen and (min-width:3000px) {
    .tox-tinymce {
      top: 16px !important;
      left: 39% !important;
      zoom: 0.8 !important;
    }
    :global(.magazine [id^=description] p),
    :global(.magazine [id^=description].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      font: normal normal normal 34px/28px Raleway;
    }
    :global(.magazine [id^=main-sub-heading] p),
    :global(.magazine [id^=main-sub-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      font-size: 34px;
      line-height: 40px;
    }
    :global(.magazine [id^=main-heading] p),
    :global(  .magazine [id^=main-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      font-size: 64px;
    }
    :global(  .magazine [id^=main-heading-page-838] p),
    :global(  .magazine [id^=main-heading-page-838].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      padding-top: 100px;
    }
    :global(.magazine [id^=description-01] p),
    :global(.magazine [id^=description-01].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine [id^=description-11] p),
    :global(.magazine [id^=description-11].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine [id^=description-06] p),
    :global(.magazine [id^=description-06].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      font: normal normal normal 40px/70px Raleway;
    }
    :global(.magazine [id^=main-sub-heading-01] p),
    :global(.magazine [id^=main-sub-heading-01].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine [id^=main-sub-heading-11] p),
    :global(.magazine [id^=main-sub-heading-11].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine [id^=main-sub-heading-06] p),
    :global(.magazine [id^=main-sub-heading-06].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      font-size: 50px;
      line-height: 60px;
    }
    :global(  .magazine [id^=main-heading-01] p),
    :global(  .magazine [id^=main-heading-01].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(  .magazine [id^=main-heading-11] p),
    :global(  .magazine [id^=main-heading-11].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(  .magazine [id^=main-heading-06] p),
    :global(  .magazine [id^=main-heading-06].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      font-size: 110px;
      line-height: 140px;
    }
  }
  
  @media screen and (min-width:5000px) {
    :global(.magazine [id^=description] p),
    :global(.magazine [id^=description].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      font: normal normal normal 60px/90px Raleway
    }
    :global(.magazine [id^=main-sub-heading] p),
    :global(.magazine [id^=main-sub-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      font: normal normal normal 54px/74px Raleway;
    }
    :global(  .magazine [id^=main-heading] p),
    :global(  .magazine [id^=main-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before){
      font: normal normal bold 150px/160px Raleway;
    }
    :global(.magazine [id^=main-sub-heading2] p ) {
    font: normal normal normal 44px/50px Raleway;
    }
    :global(.magazine [id^=main-sub-heading-page-3] p ) {
      font: normal normal normal 44px/50px Raleway;
      text-align: center !important;
    }
  
    :global(  .magazine [id^=main-sub-heading-01] p),
    :global(  .magazine [id^=main-sub-heading-01].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine [id^=main-sub-heading-06] p),
    :global(.magazine [id^=main-sub-heading-06].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before) {
      font: normal normal normal 74px/94px Raleway;
  
    }
  }
  
  
  
  @media screen and (max-width: 768px) {
    :global(.magazine #main-heading-400.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before) {
      font: normal normal normal 60px/71px Impact;
    }
  
    :global(.magazine #main-heading-400 p) {
      font: normal normal normal 60px/71px Impact !important;
    }
  
    :global(.magazine [id^=main-sub-heading] p),
    :global(.magazine [id^=main-sub-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before )
    {
      font: normal normal normal 14px/18px Raleway;
    }
    :global(.magazine [id^=main-sub-heading] span),  :global(.magazine [id^=main-sub-heading] em) {
      font-size: 18px;
      line-height: 21px;
      font-weight: normal;
    }
  
    :global(.magazine #main-heading-type-4.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)) {
      font: normal normal normal 65px/76px Impact;
    }
  
    :global(.magazine #main-heading-type-4.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine #main-heading-type-4 p )
    {
      font: normal normal bold 25px/30px Raleway;
    }
    :global(.magazine #main-heading-type-4 span ), :global(.magazine #main-heading-type-4 em ){
      font-family: Raleway, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      font-size: 25px;
      line-height: 30px;
      font-weight: bold;
    }
    :global(.magazine [id^=main-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine [id^=main-heading] p)
   {
      font: normal normal bold 23px/35px Raleway
    }
    :global(.magazine [id^=main-heading] span),  :global(.magazine [id^=main-heading] em){
      font-size: 23px;
      line-height: 35px;
      font-weight: bold;
    }
  
    :global(.magazine [id^=main-sub-heading].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)) {
      font: normal normal normal 14px/18px Raleway;
    }
  
  
  
    :global(.magazine [id^=description] p),
    :global(.magazine [id^=description].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
    :global(.magazine [id^=description].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)) {
      font: normal normal normal 12px/16px Raleway;
    }
  
    :global(.magazine [id^=main-sub-heading2] span ),:global(.magazine [id^=main-sub-heading2] em ){
      font-size: 14px;
      line-height: 25px;
      font-weight: normal;
    }
  
  
    :global(.magazine [id^=main-sub-heading2].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before ),
    :global(.magazine [id^=main-sub-heading2] p )
    {
      font: normal normal normal 14px/25px Raleway
    }
  
  
    :global(.magazine [id^=main-heading-page-6] p ),
    :global(.magazine [id^=main-heading-page-6].mce-content-body[data-mce-placeholder]),
    :global(.magazine [id^=main-heading-page-6].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine [id^=main-heading-page-6].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks))
    {
      font: normal normal bold 47px/63px Raleway;
    }
  
    :global(.magazine [id^=main-heading-page-8] p ),
    :global(.magazine [id^=main-heading-page-8].mce-content-body[data-mce-placeholder]),
    :global(.magazine [id^=main-heading-page-8].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before),
    :global(.magazine [id^=main-heading-page-8].mce-content-body[data-mce-placeholder]:not(.mce-visualblocks))
    {
      font: normal normal bold 47px/63px Raleway;
    }
  
  
  }
  