.solid{
    .blur{
        padding: 0px;
    }
    .image-part{
        border-radius: 0px;
    }
    .two-blur{
        display: inline-flex;
        align-items: flex-end;
        width: 100%;
        justify-content: center;
        gap: 10px;
        height: 100%;
        .different-place{
            width: 45%;
            height: 55px;
            margin-bottom: 10px;
        }
    }
    .line{
        height: 3.5px;
        background-color: white;
        border-radius: 1px;
    }
    .three-lines{
        .first{
            width: 40%;
        }
        .second, .third{
            width: 90%;
        }
    }
    .image--two{
        .image-part{
            width: 95px;
            height: 100%;
            margin-left: 30px;
        }
    }
    .image--three{
        align-items: flex-end;
        .image-part{
            width: 100%;
            height: 50px;
            margin-bottom: 15px;
        }
    }
    .image--four{
        justify-content: flex-end;
        .image-part{
            width: 50%;
            height: 100%;
            border-radius: 10px;
        }
    }
}

@media screen and (min-width: 3000px) {
    .solid{
        .image--two{
            .image-part{
                width: 200px;
            }
        }
    }
}

@media screen and (min-width: 4000px) {
    .images-container{
        .image--three{
            .image-part{
                height: 100px;
            }
        }
    }
}