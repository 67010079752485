.cards {
  display: flex;
  width: 100.5%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  &.expandable7,
  &.expandable6,
  &.expandable5 {
    margin-top: 1rem;
  }
}

.containerSlider {
  position: relative;

  .itemsRow-4 {
    top: 25% !important;
  }
  .itemsRow-3 {
    top: 30% !important;
  }
}

.slider-item {
  transition: 0.5s;
  > div {
    width: 100%;
  }
  &.visibilityItem {
    margin-right: 28px;
  }
}

.cards {
  li {
    margin-right: 0px !important;
    list-style-type: none;
  }
  li.visibilityItem,
  li.visibilityItem ~ li {
    margin-right: 15px !important;
    padding-top: 5px;
  }
  &.expandable11,
  &.expandable8,
  &.expandable10 {
    li.visibilityItem,
    li.visibilityItem ~ li {
      margin-right: 13px !important;
    }
  }
  &.expandable11 {
    li.visibilityItem,
    li.visibilityItem ~ li {
      margin-right: 10px !important;
    }
  }
}

@media screen and (min-height: 720px) {
  .cards {
    &.expandable5 {
      width: 101% !important;
    }
    &.expandable7,
    &.expandable6 {
      width: 101% !important;
    }
  }
  .item-center {
    .content {
      margin-left: 0%;
    }
  }
}

@media screen and (min-height: 722px) {
  .cards {
    &.expandable7,
    &.expandable6,
    &.expandable5 {
      margin-top: 1rem !important;
    }
    li.visibilityItem,
    li.visibilityItem ~ li {
      margin-right: 20px !important;
    }
    &.expandable2,
    &.expandable3,
    &.expandable4,
    &.expandable7,
    &.expandable9,
    &.expandable10 {
      li.visibilityItem,
      li.visibilityItem ~ li {
        margin-right: 16px !important;
      }
    }
  }
}
@media (min-width: 2000px) {
  .expandable-list {
    li.visibilityItem,
    li.visibilityItem ~ li {
      margin-right: 0.96vw !important;
    }
  }
}
@media (min-width: 3000px) {
  .expandable-list {
    li.visibilityItem,
    li.visibilityItem ~ li {
      margin-right: 37px !important;
    }
  }
}

@media screen and (min-height: 768px) {
  .cards {
    &.expandable2,
    &.expandable3,
    &.expandable9 {
      li.visibilityItem,
      li.visibilityItem ~ li {
        margin-right: 15px !important;
      }
    }
  }
}
@media screen and (min-height: 800px) {
  .cards {
    &.expandable7,
    &.expandable6,
    &.expandable5 {
      margin-top: 1rem;
      // margin-left: 4%;
    }
  }
}
@media screen and (min-height: 960px) {
  .cards {
    &.expandable1,
    &.expandable2,
    &.expandable3,
    &.expandable4,
    &.expandable7,
    &.expandable8,
    &.expandable9,
    &.expandable10,
    &.expandable-shared {
      li.visibilityItem,
      li.visibilityItem ~ li {
        margin-right: 1vw !important;
      }
    }
    &.expandable11 {
      li.visibilityItem,
      li.visibilityItem ~ li {
        // margin-right: 16px !important;
      }
    }
    &.expandable7,
    &.expandable6 {
      width: 101% !important;
    }
  }
  .cards.expandable11 li.visibilityItem,
  .cards.expandable11 li.visibilityItem ~ li {
    margin-right: 13px !important;
  }
  .first-templates {
    li.visibilityItem,
    li.visibilityItem ~ li {
      margin-right: 1vw !important;
    }
  }
}

.shared-template {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  padding-top: 0px;
  margin: 0 auto;
  &.first-templates {
    .maincontent {
      margin-top: 6%;
    }
  }
  &.portrait-template-8 {
    .maincontent {
      margin-left: 14%;
    }
    .slider-item {
      .image {
        aspect-ratio: 3/3.6;
      }
    }
    .containerSlider #right {
      right: 5rem;
    }
    .cards {
      width: 60%;
      margin-left: 10%;
    }
    .content {
      margin-left: 10%;
    }
  }
  &.item-center {
    .content {
      margin-left: 4.5%;
    }
  }
  &.portrait-template-11 {
    margin-top: -5rem;
    transform: scale(0.8);
    .content {
      margin-left: 25%;
    }
    .cards {
      width: 62.5%;
      margin: auto;
    }
    .template-single-card {
      .image {
        background-position: top;
        aspect-ratio: 1/1.2;
      }
    }
  }
  &.portrait-template {
    .template-single-card {
      .image {
        background-color: rgba(0, 0, 0, 0.368627451);
        background-position: top;
        aspect-ratio: 1/1.27;
      }
    }
  }
  &.expandable-shared {
    .maincontent {
      display: flex;
      flex-direction: column;
      gap: 55px;
    }
    .cards {
      width: 91%;
    }
  }
  .expandable-image {
    width: 91%;
    margin-left: auto;
    margin-right: auto;
    height: 180px;
    background-size: cover;
    background-position: center;
    border-radius: 0.7rem;
    .tooltip-container {
      cursor: pointer;
      position: relative;
      display: inline-block;
      color: white;
      z-index: 999;

      svg {
        g,
        path {
          fill: white;
        }
      }

      button {
        transform: scale(1.7);
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }
      }

      .dropdown {
        height: fit-content;
        visibility: hidden;
        transition: 0.25s all ease;
        transition-delay: 0.25s;
        position: absolute;
        margin-top: 5px;
        z-index: 5;
        padding: 8px 0;
        background-color: #050505;
        color: gray;
        border-radius: 6px;
        width: 130px;
        margin-left: -145px;
        margin-top: -25px;

        p {
          font-size: 11px;
          margin: 0px;
        }
      }
    }

    .tooltip-container:hover {
      .dropdown {
        visibility: visible;
        transition: 0.3s all ease;
        transition-delay: 1s;
      }
    }
  }

  .icons {
    background-color: #0d0d0d9e;
    padding: 10px;
    display: inline-flex;
    flex-direction: column;
    margin: 0 0 0 auto;
    border-radius: 3px;
    position: absolute;
    right: 4.5%;
    z-index: 99;
    gap: 4px;
    justify-content: space-between;
    border-top-right-radius: 10px;

    svg {
      width: 15px;
      transition: 0.5s ease-in-out;
      cursor: pointer;

      g,
      path {
        opacity: 0.8;
      }

      &:hover {
        g,
        path {
          opacity: 1;
        }
      }

      path {
        fill: white;
      }
    }
  }
  .cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 35px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }

  .add-img-circle {
    position: absolute;
    right: -47px;
    top: 20%;
    opacity: 0.7;
    transform: scale(0.85);
    width: 35px;
    font-size: 2rem;
    height: 35px;
    border-radius: 50%;
    color: white;
    background-color: rgba(128, 128, 128, 0.411);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
    &:hover {
      opacity: 1;
    }
  }
  .maincontent {
    width: 100%;
    position: relative;
    .img-plus {
      position: absolute;
      right: -50px;
      top: 10%;
      opacity: 0.4;
      &:hover {
        opacity: 0.6;
      }
    }
    #right,
    #left {
      transform: scale(0.8);
    }
    #right {
      right: -80px;
    }
    #left {
      left: -90px;
    }
  }
  .hover-text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: 68%;
    margin-top: 8%;
    #Path_549_2_,
    #Path_3 {
      fill: white;
    }
  }
  .hover-text:hover span {
    width: auto;
    padding: 0 8px 0 12px;
    overflow: visible;
  }
  .hover-text:hover {
    .dropdown_container {
      visibility: visible;
    }
    svg {
      #Path_549_2_,
      #Path_3 {
        fill: white;
      }
    }
  }
  .hover-text span {
    color: gray;
  }
  .dropdown_container {
    display: block;
    flex-direction: column;
    transition: all 0.3s linear;
    position: absolute;
    padding: 15px 20px;
    background-color: #050505;
    margin-left: -115px;
    text-align: left;
    line-height: 1.5em;
    color: gray;
    border-radius: 5px;
    margin-top: 120px;
    width: 155px;
    p {
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: white;
      }
    }
  }
  .plus-minus {
    margin-top: 35px;
  }
  .img-plus {
    margin-top: 25px;
    cursor: pointer;
  }
  .minus {
    position: absolute;
    margin-left: -60px;
    margin-top: 8%;
    cursor: pointer;
  }
  .files {
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
  .content {
    margin-left: 0%;
    text-align: left;
  }
  #Layer_1:hover {
    .st0 {
      fill: white;
    }
  }
  &.template3-item {
    padding: 0px;
    .add-img-circle {
      top: 22.5%;
    }
    &:first-child {
      .cards {
        margin-top: 1.2rem;
      }
    }
    &.item-center {
      .add-img-circle {
        top: 25%;
      }
    }
  }
  &.secondrow {
    margin-top: 1rem;
    .add-img-circle {
      top: -19%;
    }
  }
  &.template4-item {
    padding: 0px;
  }
  &.template5-item {
    padding: 0px;
    &:first-child {
      .cards {
        margin-top: 0.5rem;
      }
    }
    .add-img-circle {
      top: 25%;
    }
    &.secondrow {
      margin-top: 1.5rem;
      .add-img-circle {
        top: -26%;
      }
    }
  }
}
.item-center,
.template-4,
.template-3 {
  .cards {
    margin-top: 1rem;
  }
  .add-img-circle {
    top: 22%;
  }
}
.template-4 {
  .cards {
    margin-top: 1.5rem;
  }
  .add-img-circle {
    top: 12%;
  }
}

.shared-template.template-6 {
  .icons {
    display: none;
  }
}
.template-2 {
  .hover-text {
    margin-left: 71%;
    margin-top: 9%;
  }
  .dropdown_container {
    margin-left: 124px;
  }
}

//Slider
.sliderRoom {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

body:global(.bright-mode) {
  .shared-template {
    .maincontent {
      #right {
        background-color: rgba(149, 149, 149, 0.648);
        path {
          fill: white;
        }
      }
      #left {
        background-color: rgba(149, 149, 149, 0.648);
      }
    }
  }
  .content-slide {
    color: black;
  }
  .add-img-circle-top {
    color: black;
  }
  .preview-top {
    h2 {
      color: black;
    }
  }
}

.carouselWrapper {
  position: relative;
}

.plusParent {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -4.2rem;
  .add-img-circle-top {
    opacity: 0.7;
    transform: scale(0.75);
    width: 30px;
    font-size: 2.5rem;
    color: white;
    border-radius: 50%;
    background-color: rgba(13, 13, 13, 0.6196078431);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    margin-top: 2px;
  }

  .content-slide {
    color: white;
    font-size: 12px;
    margin: 0 2px;
    position: absolute;
    width: max-content;
    right: 25px;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.233);
    padding: 3px 10px;
    backdrop-filter: blur(10px);
    border-radius: 5px;
  }
}

.preview-top {
  color: white;
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  margin-top: 0px;
  position: relative;
  transform: scale(0.9);
  margin-bottom: -1rem;
  margin-left: -1.5rem;
  .exitpreview {
    border-bottom: 1px solid white;
  }
  h2 {
    font-size: 25px;
  }
  a {
    border-bottom: 1px solid white;
    margin-top: -10px;
    color: white;
  }
}
.shared-template.previewtemplate {
  margin-top: 2rem;
  padding-left: 1rem;
  width: 78%;
  // padding-top: 2.4rem;

  .icons {
    display: none;
  }
}

@media screen and (min-height: 600px) {
  .item-center,
  .template-4,
  .template-3 {
    .cards {
      margin-top: 1.5rem;
    }
  }
  .shared-template{
    &.expandable-shared{
      .maincontent{
        gap: 10px;
      }
    }
  }
}

@media (min-height: 650px) {
  .shared-template {
    &.portrait-template {
      .slider-item {
        margin-right: 2.1rem;
        min-width: 31%;
        max-width: 31%;
        margin-top: 1.6rem;
      }
    }
    &.portrait-template-8 {
      .containerSlider #right {
        right: 9rem;
        margin-top: 3rem;
      }
      .containerSlider #left {
        left: 0rem;
        margin-top: 3rem;
      }
      &:nth-child(2) {
        .slider-item {
          margin-right: 4rem;
        }
      }
      .slider-item {
        margin-top: 2.6rem;
        min-width: 47%;
        max-width: 47%;
      }
    }
    &.portrait-template-10,
    &.portrait-template-11 {
      .template-single-card {
        .image {
          background-position: top;
        }
      }
      .slider-item {
        min-width: 23.2%;
        max-width: 23.2%;
        margin-top: 2.6rem;
      }
    }
    &.portrait-template-11 {
      margin-top: 0;
      transform: none;
      .containerSlider #right {
        right: 5rem;
      }
      .containerSlider #left {
        left: 5rem;
      }
      .slider-item {
        min-width: 30.5%;
        max-width: 30.5%;
        margin-top: 1rem;
      }
      .maincontent {
        .content {
          margin-left: 27%;
        }
      }
      .cards {
        width: 47%;
      }
    }
    &.template3-item {
      .add-img-circle {
        top: 26.5%;
      }
      &.item-center {
        .add-img-circle {
          top: 28%;
        }
      }
      &.secondrow {
        .add-img-circle {
          top: -17%;
        }
      }
    }
    &.template5-item {
      &:first-child {
        .cards {
          margin-top: 1.2rem;
        }
      }
      &.secondrow {
        .add-img-circle {
          top: -22%;
        }
      }
    }
    .maincontent {
      #right,
      #left {
        transform: scale(1);
      }
    }
    .previewtemplate {
      margin-top: 3rem;
    }
  }
  .item-center {
    .add-img-circle {
      top: 22%;
    }
  }
}
@media screen and (max-height: 800px) and (max-width: 1280px) {
  .shared-template {
    &.portrait-template-11 {
      .maincontent {
        .content {
          margin-left: 21.5%;
        }
      }
      .cards {
        width: 58%;
      }
    }
  }
}
@media (min-height: 720px) {
  .shared-template {
    &.template3-item {
      &:first-child {
        .cards {
          margin-top: 0.2rem;
        }
      }
    }
    .expandable-image {
      width: 100%;
      // margin-left: 4%;
    }
    .icons {
      right: 0;
    }
    .content {
      margin-left: 0%;
    }
    &.item-center {
      .content {
        margin-left: 0%;
      }
    }
  }
}


@media (min-height: 722px) {
  .preview-top {
    h2 {
      font-size: 20px;
      margin-bottom: 0.2rem;
    }
    button {
      font-size: 14px;
    }
  }
  .shared-template {
    .expandable-image {
      &.expandable-7 {
        height: 300px;
      }
      &.expandable-6 {
        height: 30vh;
      }
    }
    &.expandable-shared{
      .maincontent{
        gap: 20px;
      }
    }
  }
}

@media(min-height: 750px){
  .shared-template{
    &.expandable-shared{
      .maincontent{
        gap: 40px;
      }
    }
  }
}

@media screen and (min-height: 760px) {
  .shared-template {
    &.portrait-template-11 {
      .maincontent {
        .content {
          margin-left: 24%;
        }
      }
      .cards {
        width: 53%;
      }
    }
  }
}

@media (min-height: 800px) {
  .shared-template {
    &.template3-item {
      &:first-child {
        .cards {
          margin-top: 2.2rem;
        }
      }
    }
    &.template5-item {
      &:first-child {
        .cards {
          margin-top: 2.2rem;
        }
      }
    }
    .expandable-image {
      margin-bottom: -2.5rem;
    }
  }
}

@media screen and (min-height: 850px) {
  .shared-template {
    &.portrait-template-11 {
      .maincontent {
        .content {
          margin-left: 18%;
        }
      }
      .cards {
        width: 65%;
      }
    }
  }
}

@media (min-width: 1024px) {
  .shared-template {
    .cards {
      flex-wrap: nowrap;
      gap: 0px;
    }
  }
  .first-templates {
    .cards {
      margin-top: 2rem !important;
    }
  }
  .slider-item {
    max-width: 100% !important;
  }
}
@media screen and (min-width: 1800px) {
  .template-2 {
    padding-top: 100px;
  }
  .shared-template {
    &.portrait-template-11 {
      .maincontent {
        .content {
          margin-left: 25%;
        }
      }
      .cards {
        width: 50%;
      }
    }
    .expandable-image {
      &.expandable-7 {
        height: 350px;
      }
    }
  }
}
@media screen and (min-width: 2560px) {
  .shared-template {
    .maincontent {
      .img-plus,
      #right,
      #left,
      .add-img-circle {
        transform: scale(1.3);
      }
      .img-plus,
      #right {
        right: -70px;
      }
      #left {
        left: -70px;
      }
      .img-plus {
        top: 17%;
      }
    }
    .expandable-image {
      height: 180px;
    }
  }
  .preview-top {
    h2 {
      font-size: 35px;
    }
    button {
      font-size: 24px;
    }
  }

  .shared-template.previewtemplate {
    margin-top: 10.1rem;
    &.template-3 {
      padding-top: 5rem;
    }
  }
}

@media screen and (min-width: 3000px) {
  .shared-template {
    .maincontent {
      .img-plus,
      #right,
      #left,
      .add-img-circle,
      .tooltip-container button {
        transform: scale(2);
      }
      .img-plus,
      #right {
        right: -90px;
      }
      #left {
        left: -90px;
      }
    }
  }

  .preview-top {
    h2 {
      font-size: 49px;
    }
    button {
      font-size: 35px;
    }
  }

  .shared-template.previewtemplate {
    margin-top: 15.1rem;
    &.template-3 {
      padding-top: 10rem;
    }
  }
}

@media screen and (min-width: 4000px) {
  .shared-template {
    .maincontent {
      .img-plus,
      #right,
      #left,
      .add-img-circle,
      .tooltip-container button {
        transform: scale(2.5);
      }
      .img-plus,
      #right {
        right: -115px;
      }
      #left {
        left: -115px;
      }
    }
    .expandable-image {
      height: 480px;
    }
  }

  .shared-template.previewtemplate {
    margin-top: 10.1rem;
    &.template-3 {
      padding-top: 15rem;
    }
  }
  .preview-top {
    h2 {
      font-size: 59px;
    }
    button {
      font-size: 45px;
    }
  }
}
.portrait-template-10 {
  padding-top: 1.2rem;
}
.previewtemplate {
  padding-left: 1.6rem !important;
  width: 76% !important;
  // padding-top: 3.2rem !important;
  .icons {
    display: none !important;
  }
}

.portrait-template-8 {
  .maincontent {
    margin-left: 14%;
  }
  .slider-item {
    .image {
      aspect-ratio: 3/3.6;
    }
  }
  .containerSlider #right {
    right: 5rem;
  }
  .cards {
    width: 60% !important;
    margin-left: 10%;
  }
  .content {
    margin-left: 10%;
  }
}
.portrait-template-11 {
  .slider-item {
    .image {
      aspect-ratio: 3/3.6;
    }
  }
  .containerSlider #right {
    right: 5rem;
  }
  .content {
    margin-left: 25%;
  }
}

@media (min-width: 2000px) {
  .cards {
    &.expandable2,
    &.expandable3,
    &.expandable4,
    &.expandable7,
    &.expandable9,
    &.expandable10 {
      li.visibilityItem,
      li.visibilityItem ~ li {
        margin-right: 22px !important;
      }
    }
  }
}
@media (min-width: 2400px) {
  .cards {
    &.expandable2,
    &.expandable3,
    &.expandable4,
    &.expandable7,
    &.expandable9,
    &.expandable10 {
      li.visibilityItem,
      li.visibilityItem ~ li {
        margin-right: 25px !important;
      }
    }
  }
}

@media (min-width: 2800px) {
  .cards {
    &.expandable2,
    &.expandable3,
    &.expandable4,
    &.expandable7,
    &.expandable9,
    &.expandable10 {
      li.visibilityItem,
      li.visibilityItem ~ li {
        margin-right: 30px !important;
      }
    }
  }
  .cards.expandable11 li.visibilityItem,
  .cards.expandable11 li.visibilityItem ~ li {
    margin-right: 0.7vw !important;
  }
}
.portrait-template-8 {
  #right {
    right: 22% !important;
  }
  #left {
    left: 2% !important;
  }
}
.portrait-template-11 {
  #right {
    right: 19% !important;
    top: 35% !important;
  }
  #left {
    left: 19% !important;
    top: 35% !important;
  }
}
@media (min-width: 2000px) {
  .portrait-template-8 {
    #right {
      right: 320px !important;
    }
    #left {
      left: -15px !important;
    }
  }
}
.portrait-template-10,
.portrait-template-9 {
  #left {
    top: 43% !important;
  }
}
@media (min-width: 3200px) {
  .cards {
    &.expandable2,
    &.expandable3,
    &.expandable4,
    &.expandable7,
    &.expandable9,
    &.expandable10 {
      li.visibilityItem,
      li.visibilityItem ~ li {
        margin-right: 39px !important;
      }
    }
  }
}
.portrait-template-8 {
  #right {
    margin-top: 2rem;
  }
  #left {
    margin-top: 2rem;
  }
}
.template-4 {
  #right,
  #left {
    margin-top: 1rem;
  }
}
@media (min-width: 2000px) {
  .template-4,
  .template-3 {
    #right,
    #left {
      margin-top: 3rem;
    }
  }
  .first-templates {
    #right,
    #left {
      margin-top: 2rem;
    }
  }
  .portrait-template-11 {
    #right {
      margin-right: 3rem;
    }
    #left {
      margin-left: 3rem;
    }
    #right,
    #left {
      margin-top: 1rem;
    }
  }
  .portrait-template-8 {
    #right {
      margin-right: 4rem;
    }
    #left {
      margin-left: 4rem;
    }
    #right,
    #left {
      margin-top: 4rem;
    }
  }
}
.first-templates {
  li.visibilityItem,
  li.visibilityItem ~ li {
    margin-right: 1vw !important;
  }
}
@media (min-width: 2000px) and (min-height: 890px) {
  .first-templates {
    li.visibilityItem,
    li.visibilityItem ~ li {
      margin-right: 1vw !important;
    }
  }
}
#left {
  position: absolute;
  left: -90px;
  top: 50%;
  background: #34343459;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
#right {
  position: absolute;
  right: -80px;
  background: #34343459;
  border-radius: 50%;
  width: 35px;
  top: 50%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  path {
    fill: rgb(160, 160, 160);
  }
  &:hover {
    background-color: #343434c7;
    path {
      fill: rgba(255, 255, 255, 0.791);
    }
  }
}
.backdrop-card {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  filter: blur(30px) brightness(0.8);
  .doc-container {
    border-radius: 0;
  }
  .card-backdrop-video {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    .mainplaybtn {
      display: none;
    }
    video {
      width: 100vw;
      height: 100vh;
      object-fit: fill;
      object-position: center;
    }
  }
}
.single-show-item {
  padding-top: 5px;
}
@media (max-width: 1024px) {
  .slider-fixed-popup {
    background: rgba(0, 0, 0, 0.412);
    backdrop-filter: blur(30px);
    .back-btn {
      transform: scale(0.8);
    }
  }
  .slider-fixed-popup .slider-popup-container .arrow-container button {
    width: 20px;
    display: block;
  }
  .slider-footer-control {
    height: 20px !important;
    opacity: 0.5;
    .slider-logo {
      margin-left: 0 !important;
      .mw-logo {
        display: none;
      }
      .slider-pagination {
        transform: scale(0.6);
        display: none;
      }
    }
    .slider-control {
      margin-right: 5px !important;
      .svg-container {
        opacity: 0.6;
        svg {
          width: 15px !important;
          height: 15px !important;
        }
        &:not(:last-child) {
          display: none;
        }
      }
    }
  }
  .designtool-template {
    justify-content: flex-start;
    overflow: hidden;
    min-height: 80vh !important;
    .main-shared-text h3 {
      margin: 0;
    }
    .vertical-template-preview {
      background: none !important;
    }
    .rightfile,
    .leftfile {
      bottom: 0% !important;
      position: fixed;
      z-index: 99999;
    }
    .cards {
      align-items: flex-start !important;
      justify-content: flex-start !important;
    }
    .slider-arrows {
      margin-top: 15px;
      position: relative;
      justify-content: center;
      gap: 10px;
      .st0 {
        fill: transparent !important;
      }
    }
    .leftfile {
      left: 45%;
    }
    .rightfile {
      right: 45%;
    }
    .filesSingle {
      min-height: 40vh;
      justify-content: flex-start;
    }
  }
  .preview-template {
    min-height: 60vh !important;
    max-height: 75vh !important;
    padding-bottom: 2vh;
    width: 95% !important;
    overflow: hidden;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 20px;
      z-index: -1;
      backdrop-filter: blur(50px);
    }
  }
  .shared-template {
    padding: 0 !important;
    max-width: none !important;
    width: 100% !important;
    overflow: hidden;
    .textcontainer {
      margin-top: 10px !important;
    }
    .main-shared-text p {
      font-size: 1.4em;
    }
    .main-shared-text h3 p {
      font-size: 17px;
    }
    .cards {
      flex-wrap: nowrap;
      gap: 2px;
      margin-top: 2vh;
    }
    .slider-item {
      min-width: 100% !important;
      max-width: 100% !important;
      margin: 0 !important;
    }
    li,
    .visibilityItem {
      margin: 0 !important;
    }
    .cards.expandable1 li.visibilityItem {
      margin-right: 0 !important;
    }
    .maincontent {
      max-width: none !important;
      width: 95% !important;
      padding: 10px;
      #right {
        right: 43%;
        margin-top: 10px;
        top: auto !important;
        bottom: 10% !important;
        position: fixed;
      }
      #left {
        left: 43% !important;
        position: fixed;
        top: auto !important;
        bottom: 10% !important;
      }
    }
  }
  .designtool-template {
    &.designtool-landscape {
      min-height: 100vh !important;
    }
    .landscape-mobile-template-preview {
      margin-top: 0;
      width: 70% !important;
      max-height: 92vh !important;
      .shared-template {
        transform: none !important;
      }
      .shared-template.previewtemplate {
        margin-top: 0;
      }
      .maincontent {
        width: 70% !important;
        #right {
          width: 30px;
          height: 30px;
          bottom: auto !important;
          top: 55% !important;
          left: auto !important;
          right: 8% !important;
        }
        #left {
          width: 30px;
          height: 30px;
          bottom: auto !important;
          top: 45% !important;
          left: auto !important;
          right: 8% !important;
        }
      }
      .shared-template.expandable-shared .cards {
        width: 100%;
      }
    }
  }
}
@media (max-width: 1024px) and (min-height: 768px) {
  .designtool-template {
    .landscape-mobile-template-preview {
      .maincontent {
        width: 80% !important;
      }
    }
  }
}
@media (max-width: 570px) {
  .preview-template {
    min-height: 60vh !important;
    max-height: 70vh !important;
  }
  .designtool-template {
    .cards {
      align-items: flex-start !important;
      justify-content: flex-start !important;
    }
    .rightfile,
    .leftfile {
      bottom: 10% !important;
    }
    .leftfile {
      left: 35% !important;
    }
    .rightfile {
      right: 35% !important;
    }
  }
  .shared-template {
    .maincontent {
      #right {
        right: 41% !important;
        margin-top: 10px;
        top: auto !important;
        bottom: 25% !important;
      }
      #left {
        left: 37% !important;
        top: auto !important;
        bottom: 25% !important;
      }
    }
  }
}
.template-3 {
  #right,
  #left {
    top: auto !important;
    margin-top: -20vh !important;
  }
}
.template-4 {
  #right,
  #left {
    top: auto !important;
    margin-top: -10vh !important;
  }
}
