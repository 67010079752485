.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 13px;
  width: 100%;
  .imageWrapper {
    width: 100%;
    border-radius: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      position: absolute;
      padding: 10px 17px;
      margin: 0;
      visibility: hidden;
      transform: scale(0);
      font: 500 20px/22px Raleway;
      color: white;
      border: none;
      opacity: 0;
      transition:
        visibility 0s,
        transform 0.4s ease-in-out,
        opacity 0.4s ease-in-out;
    }

    .image {
      width: 90%;
      height: auto;
      border-radius: 15px;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      .image {
        filter: brightness(0.3);
      }

      button {
        visibility: visible;
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  .title {
    font: 600 24px/30px Raleway;
    color: white;
    margin-top: 15px;
    padding-left: 20px;
    text-align: left;
  }

  .desc {
    font: 300 18px/24px Raleway;
    text-align: left;
    color: #828282;
    padding-left: 20px;
  }

  &:hover {
    .image {
      transform: scale(1.04);
    }

    .desc {
      color: #bcbcbc;
    }
  }
}

@media (max-width: 1199px) {
  .card {
    .title {
      font: 600 20px/23px Raleway;
    }
  }
}

@media (max-width: 991px) {
  .card {
    align-items: center;
    gap: 0px;

    .image {
      max-width: 70%;
      width: 100%;
      margin-bottom: 10px;
    }

    .title {
      font: 600 25px/30px Raleway;
    }
  }
}

@media (max-width: 575px) {
  .card {
    padding: 20px 30px 40px 30px;

    .image {
      max-width: 80%;
      width: 100%;
    }
  }
}
