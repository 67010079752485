.middleSection {
  background: linear-gradient(180deg, #000 0%, #121212 11.63%, #212121 100%);
}

.descSection {
  max-width: 80%;
  display: grid;
  grid-template-columns: 0.4fr 1fr 0.25fr;
  gap: 70px;
  align-items: center;
  margin: 0 auto;
  padding: 100px 20px 80px 20px;

  .title {
    color: #ebebeb;
    font: 900 45px/50px Raleway;
    border-right: 1px solid #777;
    padding-right: 10px;
    text-align: left;
  }

  .desc {
    color: #828282;
    font: normal 18px/23px Raleway;
    text-align: left;
  }

  .btn {
    background-color: #ec7600;
    color: white;
    padding: 10px 20px;
    font-size: 15px;
    border-radius: 30px;
    transition: all 0.4s ease-in-out;
    white-space: nowrap;

    &:hover {
      background-color: #ec7600;
      cursor: pointer;
      transform: scale(1.08);
    }
  }
}

.topBanner {
  min-height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../../../../assets/images/bannerBackground.png);
  background-position: bottom;
  background-size: cover;

  .title {
    font: 900 64px/70px Raleway;
    color: white;
    padding: 0 50px;
    text-align: center;
  }
}

@media (max-width: 1199px) {
  .descSection {
    max-width: 90%;

    .title {
      font: 900 35px/40px Raleway;
    }
  }

  .topBanner {
    .title {
      font: 900 55px/60px Raleway;
    }
  }
}

@media (max-width: 991px) {
  .descSection {
    padding: 70px 20px 30px 20px;
    grid-template-columns: 0.5fr 1fr;
    gap: 30px;

    .title {
      font: 900 30px/35px Raleway;
    }

    .desc {
      font: normal 16px/22px Raleway;
    }

    .btn {
      font-size: 13px;
      padding: 7px 10px;
      width: fit-content;
    }
  }

  .topBanner {
    .title {
      font: 900 45px/50px Raleway;
    }
  }
}

body:global(.purple-mode) {
  .middleSection {
    background: rgb(59 70 108);
    * {
      color: white;
    }
  }
}
body:global(.green-mode) {
  .middleSection {
    background: rgb(46, 73, 65);
    * {
      color: white;
    }
  }
}
body:global(.blue-mode) {
  .middleSection {
    background: rgb(1, 37, 59);
    * {
      color: white;
    }
  }
}
