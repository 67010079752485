.general-page-wrapper {
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  .main-nav-templates{
    svg{
      width: 28px;
      height: 30px;
      g,path{
        fill: #ec6700;
      }
    }
  }
  .bottom-main-btns {
    position: fixed;
    bottom: 1rem;
    right: 2.5rem;
    z-index:9;
    display: flex;

    svg {
      width: 22px;
      height: 22px;
      margin: 0 4px;
      cursor: pointer;

      g,
      path {
        fill: rgba(255, 255, 255, 0.329);
      }

      &:hover {

        g,
        path {
          fill: white;
        }
      }
    }
  }

  .universalmenu-icon {
    position: fixed;
    top: 70px;
    left: 1.5%;
    z-index: 1;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgba(256, 256, 256, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
    opacity: 1;
    cursor: pointer;

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  .uni-menu-wrapper {
    width: 300px !important;
    position: fixed !important;
    z-index: 99999999;

    * {
      text-align: left;
    }

    button {
      text-align: center;
    }

    .uni-menu-size {
      height: 95%;
      top: 45px;
    }

    .uni-menu-footer {
      position: relative;
      margin-bottom: 25px;
      margin-top: auto;
      p{
        right: unset;
      }
    }
  }
}


//THEMES
.bright-mode {
  .general-page-wrapper {
    background-color: #d1cfcf;
    .universalmenu-icon {
      background-color: rgba(256, 256, 256, 0.4);

    }
  }
} 
.purple-mode,.green-mode,.blue-mode{
  .main-nav-templates{
    svg{
      g,path{
        fill: white;
      }
    }
  }
.general-page-wrapper{
  background-position: center;
  background-size: cover;
  background-image: url(../../../assets/images/studio-themes-bg.webp);
  position: relative;
  .themeafter{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    z-index: -1;
    width: 100%;
    height: 100%;
  }
}
}
.bg-images{
  .general-page-wrapper{
    .themeafter{
      background-color: transparent!important;
      opacity: 1;
    }
  }
}
.purple-mode{
  .gradientLine{
    background: linear-gradient(to right, #5b87d8c9,#507dd0c9, rgba(255, 255, 255, 0.68));
  }
  .main-nav-templates{
    background-color: #1e2836;
  }
  .general-page-wrapper{
    .themeafter{
      background-color: #232d3d;
      opacity: 0.9;
    }
  }
}
.green-mode{
  .gradientLine{
    background: linear-gradient(to right, #327f67,#186c51, rgba(255, 255, 255, 0.68));
  }
  .general-page-wrapper{
    .themeafter{
      background-color: #1f342fe4;
    }
  }
  .main-nav-templates{
    background-color: rgb(19 68 55);
  }
}
.blue-mode{
  .gradientLine{
    background: linear-gradient(to right, #0175B2,#056395, rgba(255, 255, 255, 0.68));
  }
  .main-nav-templates{
    background-color: #104971;
  }
  .general-page-wrapper{
   .themeafter{
      background-color: #01365cde;
    }
  }
}
.backdrop-showroom {
  background-size: cover;
  background-position: center;
@media(max-width:990px){
  overflow: hidden;
  height: 100vh;
}
  .preview-template {
    background: rgba(82, 82, 82, 0.271) !important;
    position: relative;
    z-index: 3;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      content: '';
      z-index: -1;
      backdrop-filter: blur(50px);
    }

  }
  .preview-templateBlur {
    background: rgba(82, 82, 82, 0.271) !important;
  }
}

@media screen and (min-height: 650px) {
  .general-page-wrapper .uni-menu-wrapper .uni-menu-size{
    top:55px;
  }
}

@media screen and (max-width: 768px){
  .general-page-wrapper {
    .main-nav-templates{
      svg{
        width: 20px;
        height: 21px;
      }
    }
  }
}

@media screen and (min-width: 1550px) {
  .preview-templateBlur {
    margin-top: 2rem;
  }
}

@media screen and (min-width: 2560px) {
  .general-page-wrapper{
    .universalmenu-icon{
      top: 80px;
    }
    .bottom-main-btns svg{
      width: 30px;
      height: 30px;
    }
  }
}
@media screen and (min-width: 3000px) {
  .general-page-wrapper{
    .bottom-main-btns svg{
      width: 50px;
      height: 50px;
    }
    .universalmenu-icon{
      top:110px;
      transform: scale(1.5);
    }
    .main-nav-templates {
      a svg {
        width: 45px!important;
        height: 45px;
      }
      .logosvg {
        width: 45px!important;
        height: 45px;
      }
    }
  }
}
@media screen and (min-width: 4000px) {
  .general-page-wrapper{
    .bottom-main-btns svg{
      width: 65px;
      height: 65px;
      margin:0 6px;
    } 
    .universalmenu-icon{
      top:140px;
      transform: scale(2);
    }
    .main-nav-templates {
      a svg {
        width: 60px!important;
        height: 60px;
        margin-top: 10px;
      }
      .logosvg {
        width: 60px!important;
        height: 60px;
      }
    }
  }
}

.mobile-cover-image{
  background-image: url('../../../assets/images/studio-mobile.png') !important;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: fixed;
  background-size: cover;
  background-position: center;
  *{
    display: none !important;
  }
}
.landscape-content-wrapper{
  .logo-sharing,.preview-navigation{
    display: none;
  }
}

@media (max-width: 767px){
  .general-page-wrapper{
    .bottom-main-btns svg{
      display: none;
    }
  }
}