.btn {
    opacity: 0.3;
    position: fixed;
    top: 1rem;
    cursor: pointer;
    left: 2rem;
    left: 11rem;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: rgb(252, 252, 252);
    z-index: 9999999999;
    border: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    // background-color: gray;
    border: 1px solid rgb(232, 232, 232);
    padding: 2px;
    opacity: 1;
    &.backbtnslider{
      left: 1rem;
      margin-top: -5px;
      background-color: #9e9e9e5a;;
      backdrop-filter: blur(30px);
    }
    &:hover{
      span{
        visibility: visible;
      }
    }
span{
  position: absolute;
  right: -45px;
  font-size: 15px;
  margin-top: 2px;
  color: white;
  visibility: hidden;
}
   &.previewbtn{
    top:1rem;
   }
    svg {
      width: 18px;
      height: 18px;
      g{
        fill: white !important;
      }
      path{
        fill: transparent !important;
      }
    }
  
    &:hover {
      opacity: 1;
    }
  }
  
  body:global(.bright-mode) {
    .btn {
      svg {
  
        g,
        path {
          fill: black;
        }
      }
    }
  }
  @media (max-width: 768px){
    .btn {
      left: 7.5rem;
    }
  }
  @media (max-width: 1550px){
    .btn {
      font-size: 16px;
      svg{
        width: 24px!important;
        padding-bottom: 2px;
      }
    }
  }
  @media (min-width: 2560px) and (min-height: 1440px){
    .btn {
      top: 5.1rem;
      left: 6.3rem;
      font-size: 22px;
      svg{
        width: 33px !important;
      }
    }
  }
  @media (min-width: 3840px) and (min-height: 2160px)  {
    .btn {
      top: 6.2rem;
      left: 8.3rem;
      font-size: 35px;
      svg{
        width: 45px !important;
      }
    }
  }
  @media (min-width: 5120px) and (min-height: 2880px)  {
    .btn {
      top: 7.7rem;
      left: 9.5rem;
      font-size: 40px;
      svg{
        width: 50px !important;
        margin-top: 5px!important;
      }
    }
  }