.detailed-page{
    width: 75%;
    margin: 0 auto;
    .image-part.no-background{
        background-color: #2e2d2d5e;
        background-blend-mode: overlay;
        .blur{
            background-color: #ffffff00;
            backdrop-filter: none;
        }
    }
    .image-part .wrapper .dark-blur{
        background-color: #ffffff21;
    }
    .backgrounds{
        display: inline-flex;
        gap: 6px;
        backdrop-filter: blur(30px);
        background: #ffffff21 0% 0% no-repeat padding-box;
        padding: 6px;
        border-radius: 5px;
        margin-top: 5px;
        margin-left: 10px;
        img{
            height: 15px;
            cursor: pointer;
        }
        .colors{
            display: inline-flex;
            gap: 5px;
            align-content: center;
            .dropdown_container{
                .dark{
                    background-color: #373737;
                    height: 101%;
                    width: 18px;
                    border-radius: 5px;
                    cursor: pointer;
                    &:hover{
                        border: 2px solid white;
                    }
                }
            }
            .white-icon{
                background-color: #ffffff;
                height: 101%;
                width: 16px;
                border-radius: 5px;
                cursor: pointer;
            }
        }
        &.sharingmode{
            display: none;
        }
    }
    .image-part{
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        color: white;
        padding: 4%;
        height: 70vh;
        display: flex;
        align-items: center;
        -webkit-transition: background-color 1s ease-out;
        -moz-transition: background-color 1s ease-out;
        -o-transition: background-color 1s ease-out;
        transition: background-color 1s ease-out;
        .background-color {
            background: #0000002d 0% 0% no-repeat padding-box;
        }
        .dark-blur{
            background-color: rgba(232, 232, 232, 0.215) !important;
        }
        .content{
            display: inline-flex;
            width: 100%;
            .wrapper{
                display: inline-flex;
                flex-direction: column;
                align-items: flex-start;
                max-width: 360px;
                width: 80%;
            }
        }
        .line{
            height: 2.5px;
            background-color: white;
            border-radius: 2px;
        }
    }
    .text-position{
        display: inline-flex;
        gap: 3px;
        backdrop-filter: blur(30px);
        background: #ffffff21 0% 0% no-repeat padding-box;
        padding: 4px 4px;
        border-radius: 5px;
        .color{
            cursor: pointer;
            height: 13px;
            width: 13px;
            border-radius: 50%;
            &.activeBlock{
                border: 1px solid white;
            }
            background-color: #595959;
            &:hover{
              opacity: 0.8;
            }
        }
        &.sharingmode{
            display: none;
        }
    }
    .blur{
        background: #ffffff21 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        backdrop-filter: blur(30px);
        padding: 30px 60px;
        text-align: left;
        -webkit-transition: background-color 1s ease-out;
        -moz-transition: background-color 1s ease-out;
        -o-transition: background-color 1s ease-out;
        transition: background-color 1s ease-out;
        min-height: 450px;
        width:100%;
        height:450px;
        overflow-y: auto;
        p{
            line-height: 1.5em;
        }
        a{
            color:#FF8000;
            text-decoration: underline;
        }
        .link-icon{
            display: flex;
            align-items: center;
            gap: 10px;
            position: relative;
            > button {
                font-size: 20px;
                color: #ACACAC;
                text-decoration: underline;
                margin-top:5px;
            }
            .popup{
                max-height: 0;
                overflow: hidden;
                position: absolute;
                bottom: 20px;
                right: -30px;
                background-color: #333333;
                display: flex;
                flex-direction: column;
                transition: .3s max-height ease-out;
                .rooms-choices{
                    max-height: 200px;
                    overflow-y: scroll;
                    > div {
                        display: flex;
                        align-items: center;
                        color: #B5B5B5;
                        position: relative;
                        cursor: pointer;
                        input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                        }
                        .checkmark {
                            position: absolute;
                            top: 4px;
                            left: -15px;
                            height: 8px;
                            width: 8px;
                            background-color: transparent;
                            border: 1px solid #707070;
                        }
                        &:not(:last-child){
                            padding-bottom: 20px;
                        }
                    }
                    > .selected {
                        .checkmark {
                            background-color: #FFFFFF;
                        }
                    }
                    padding: 21px 31px;
                    display: flex;
                    flex-direction: column;
                }
                button{
                    background-color: #707070;
                    color: #FFFFFF;
                    align-self: center;
                    padding: 4px 15px;
                    border-radius: 5px;
                }
            }
            .open{
                max-height: 300px;
                transition: .3s max-height ease-out;
            }
        }
    }
 .link-icon{
        padding-top: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
        margin-left: 10px;
        > button {
            font-size: 16px;
            color: #ACACAC;
            text-decoration: underline;
        }
        &.sharingmode{
            display: none;
        }
    }
}
    
    @media screen and (min-height: 550px) {
        .detailed-page{
            .backgrounds{
                margin-top: 10px;
            }
        }
    }
    
    @media screen and (min-height:750px) {
        .detailed-page{
            .blur{
                .link-icon{
                    > button {
                        margin-top:15px;
                    }
                }
            }
            .link-icon{
                padding-top: 15px;
                > button {
                    font-size: 20px;
                }
            }
        }
    }

    @media screen and (min-width: 2560px) {
        .detailed-page{
            .backgrounds{
                img{
                    height: 20px;
                }
                .colors{
                    gap: 10px;
                    .dropdown_container .dark, .white-icon{
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .blur {
                height: 650px;
            }
            .image-part{
                    height: 68vh;
            }
            .link-icon{
                > button {
                    font-size: 22px;
                }
            }
        }
    }
    @media screen and (min-width: 3000px) {
        .detailed-page{
            .backgrounds{
                img{
                    height: 30px;
                }
                .colors{
                    .dropdown_container .dark, .white-icon{
                        width: 30px;
                        height: 30px;
                    }
                }
            }
            .blur {
                height: 900px;
                .link-icon{
                    > button {
                        font-size: 22px;
                    }
                }
            }
            .text-position{
                .color{
                    width: 30px;
                    height: 30px;
                }
            }
            .text-position{
                gap:10px;
                .color{
                    width: 20px;
                    height: 20px;
                }
            }
            .link-icon{
                > button {
                    font-size: 33px;
                }
            }
        }
    }
    @media screen and (min-width: 4000px) {
        .detailed-page{
            .backgrounds{
                img{
                    height: 40px;
                }
                .colors{
                    .dropdown_container .dark, .white-icon{
                        width: 40px;
                        height: 40px;
                    }
                }
            }
            .blur {
                height: 1150px;
                  .link-icon{
                        > button {
                            font-size: 45px;
                        }
                    }
                }      
            .text-position{
                .color{
                    width: 40px;
                    height: 40px;
                }
            }
            .link-icon{
                > button {
                    font-size: 45px;
                }
            }
        }
    }
