a{
  text-decoration: none;
}
*{
margin: 0;
padding: 0;
}
p{
font-size: 16px;
}
button,input{
  background: none;
  border: none;
}
button{
cursor: pointer;
}
body{
  background-color: #131313 !important;
}
.reused-component{
  max-width: 100rem;
}