.modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(25px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    margin: 0 auto;
    .frame{
        padding: 4rem 0;
        border-radius: 10px;
        background-color: rgb(36, 36, 36);
        color: white;
        max-width: 60%;
        margin: 0 auto;
        position: relative;
        span{
            position: absolute;
            top: 10px;
            right: 20px;
            color: white;
            font-size: 26px;
            opacity: 0.7;
            cursor: pointer;
            &:hover{
                opacity: 1;
            }
        }
        button{
            color: white;
            font-size: 16px;
            padding: 8px 25px;
            background-image: linear-gradient(
                50deg,
                #38ad9493,
                #4763988e,
                #e46b2085
              );
            border-radius: 5px;
            margin-top: 1rem;
        }
        h1{
            font-size: 2.2rem;
            margin-bottom: 5px;
        }
        p{
            font-size: 18px;
            opacity: 0.7;
        }
    }
}