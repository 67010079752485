.images-container{
    margin: 0px auto;
    width: 100%;
    margin-bottom: 1.5rem;
    .content{
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
    }
    .blur{
        width: 24.5%;
        border-radius: 10px;
        opacity: 1;
        padding: 15px;
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-flex;
        align-items: center;
        background-position: right;
        cursor: pointer;
        aspect-ratio:  16/9;
    }
    .line{
        height: 3.5px;
        background-color: white;
        border-radius: 1px;
    }
    .five-lines{
        .first{
            width: 40%;
        }
        .second, .third, .four{
            width: 95%;
        }
        .five{
            width: 80%;
        }
    }
    .four-lines{
        .first{
            width: 60%;
        }
        .second, .third, .four{
            width: 95%;
        }
        .five{
            display: none;
        }
    }
    .two-lines{
        .first{
            width: 25%;
        }
        .second {
            width: 95%;
        }
        .four, .five, .third{
            display: none;
        }
    }
    .different-place{
        background: #00000069 0% 0% no-repeat padding-box;
        border-radius: 5px;
        opacity: 1;
        backdrop-filter: blur(30px);
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        padding: 10px;
    }
    .image-one{
        justify-content: flex-end;
        align-items: flex-start;
        .image-part{
            width: 80px;
            height: 95%;
        }
    }
    .image-two{
        justify-content: flex-end;
        padding: 0px;
        .image-part{
            width: 100px;
            height: 100%;
        }
    }
    .image-three{
        justify-content: flex-end;
        .image-part{
            width: 90px;
            height: 90%;
            border-radius: 45%;
        }
    }
    .image-four{
        align-items: flex-end;
        justify-content: center;
        .image-part{
            width: 90%;
            height: 50px;
        }
    }
}

@media screen and (max-width: 768px) {
    .images-container{
        .content{
            flex-wrap: wrap;
            height: 100%;
        }
    }
}

@media screen and (min-width: 3000px) {
    .images-container{
        .different-place{
            gap: 10px;
        }
        .image-one, .image-two, .image-three{
            .image-part{
                width: 200px;
            }
        }
    }
}
@media screen and (min-width: 4000px) {
    .images-container{
        .image-four{
            .image-part{
                height: 100px;
            }
        }
    }
}
