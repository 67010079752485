body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   padding: 0;
}
/* @media screen and (max-width: 768px) {
  .studio-body{
    background-image: url('../images/studio-mobile.png') !important;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    width: 100vh;
    height: 100vh;
    position: fixed;
    visibility: hidden;
  }
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tox-tinymce {
  left: 21.5%  !important;
  top: 50px !important;
  border: none !important;
}
button[aria-label="Undo"],button[aria-label="Redo"],button[aria-label="Bold"],button[aria-label="Italic"],button[aria-label="Align left"],button[aria-label="Align center"],button[aria-label="Align right"]{
  width:24px !important;
}
.tox .tox-tbtn svg{
    transform: scale(0.8);
}
.tox .tox-tbtn{
    width:28px;
}
.tox-tbtn--select span{
    font-size: 13px!important;
}
.tox-tbtn--select {
    width: 70px !important;
}

.tox-tinymce-inline .tox-editor-header{
  border: none !important;
}
.mce-content-body {
  padding: unset !important;
}

body{
  padding: 0!important;
}

.mce-content-body p {
  margin: 0!important;
}
.tox .tox-toolbar--scrolling{
  width: max-content !important;
  overflow: visible !important;
}

.tox-tinymce-inline .tox-editor-header{
  border: none !important;
}
.mce-content-body {
  padding: unset !important;
}

@media(min-height:655px){
  .tox-tinymce{
    top:69px !important;
  }
}
@media(min-width:1000px){
  .tox-tinymce{
    left: 25% !important;
  }
}
@media(min-width:1150px){
  .tox-tinymce{
    left: 26.5% !important;
  }
}
@media(min-width:1220px){
  .tox-tinymce{
    left: 27% !important;
  }
  button[aria-label="Undo"],button[aria-label="Redo"],button[aria-label="Bold"],button[aria-label="Italic"],button[aria-label="Align left"],button[aria-label="Align center"],button[aria-label="Align right"]{
    width: 26px !important;
  }
}
@media(min-width:1280px){
  .tox-tinymce{
    left: 29% !important;
  }
}
@media(min-width:1350px){
  .tox-tinymce{
   left: 30% !important;
 }
 .tox-collection__item-checkmark{
   width: 0px!important;
   margin-left: 0px!important;
 }
}
@media(min-width: 1440px){
  .tox-tinymce{
    left: 31% !important;
  }
}
@media(min-width: 1500px){
  .tox-tinymce{
    left: 31.5% !important;
  }
}
@media(min-width: 1800px){
  .tox-tinymce{
    left: 33%  !important;
  }
}
@media(min-width: 1600px){
  button[aria-label="Undo"],button[aria-label="Redo"],button[aria-label="Bold"],button[aria-label="Italic"],button[aria-label="Align left"],button[aria-label="Align center"],button[aria-label="Align right"]{
    width: 34px !important;
  }
  .tox .tox-tbtn svg{
    transform: scale(0.85);
  }
}
@media(min-width:2560px){
  .tox-tinymce{
    top: 80px !important;
  }
  .tox .tox-tbtn svg, .tox-tbtn__select-chevron svg{
    transform: scale(1.1);
  }
  .tox-toolbar, .tox-toolbar__group{
    gap:5px;
  }
  .tox-toolbar{
    width: 600px!important;
  }
  .tox-toolbar__group .tox-tbtn--select {
    width: 185px !important;
  }
  .tox-tbtn--select span{
    font-size: 20px!important;
  }
  .tox-toolbar__group button[title=Fonts] .tox-tbtn__select-chevron {
    margin-top: 16%;
  }
}

@media(min-width:3000px){
  .tox-tinymce{
    top: 155px !important;
  }
  .tox .tox-tbtn svg, .tox-tbtn__select-chevron svg{
    transform: scale(2);
  }
  .tox-toolbar, .tox-toolbar__group{
      gap:35px;
  }
  .tox-toolbar{
      width: 1000px!important;
  }
  .tox-toolbar__group .tox-tbtn--select {
    width: 200px !important;
  }
  .tox-tbtn--select span{
    font-size: 28px!important;
  }
}

@media(min-width:3500px) {
  .tox .tox-tbtn--bespoke .tox-tbtn__select-label {
    width: 3em !important;
  }
  .tox-tbtn {
    overflow: unset !important;
  }

  .tox .tox-tbtn--select {
    width: 130px !important;
  }
}


@media(min-width:4000px) {
  .tox-tinymce {
    top: 180px !important;
  }

  .tox .tox-split-button__chevron svg {
    margin-top: 35px !important;
  }

  .tox .tox-split-button .tox-tbtn {
    width: 55px !important;
  }

  .tox .tox-split-button__chevron {
    width: 37px !important;
  }

  .tox .tox-tbtn svg,
  .tox-tbtn__select-chevron svg {
    transform: scale(2.4);
  }

  .tox-toolbar,
  .tox-toolbar__group {
    gap: 55px;
  }

  .tox-tbtn {
    height: 60px !important;
  }

  .tox-tbtn--select span {
    font-size: 40px !important;
  }

}

.magazine{
  background-color: #1a1a1a ;
}