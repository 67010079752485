.buttons-licences {
    float: right;
    margin-top: 4%;
    button {
        color: white;
    }
    a, button{
        font-size: 16px;
        font-weight: bold;
    }
    a{
        padding: 7px 25px;
        border-radius: 5px;
        font-size: 14px;
        color: white;
        margin-left: 10px;
        background-color: #ec7600;
    }
}
@media screen and (min-width: 3000px) {
    .buttons-licences {
        margin-top: -2%;
    }
}