.shared-template {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  padding-top: 0px;
  margin: 0 auto;
  transform: scale(0.85);
  margin-top: -2.5rem;
  @media(min-width:2000px){
    margin-top: 3%;
  }
  &.first-templates{
    .maincontent{
      margin-top: 6%;
    }
  }
  &.portrait-template-8{
    .maincontent{
      margin-left: 18%;
    }
    .slider-item{
      .image{
        aspect-ratio: 3/3.6 ;
      }
    }
    .containerSlider #right{
      right: 5rem;
    }
    .cards{
      width:60% ;
      margin-left: 10%;
    }
    .content{
      margin-left: 10%;
      max-width: 800px;
    }
  }
  &.portrait-template-11{
    width: 69%;
    
    .content{
      margin-left: 25%;
      }
    .cards{
      width: 50%;
      margin: auto;
    }
      .template-single-card{
        .image{
          background-position: top;
          aspect-ratio: 1/1.2;
  
        }
      }
    }
    &.portrait-template-9{
      .containerSlider .itemsRow-3{
        top: 43.5%!important;
      }
    }
  &.portrait-template{
    .template-single-card{
      .image{
        background-color: rgba(0, 0, 0, 0.368627451);
        background-position: top;
        aspect-ratio: 1/1.27;
      }
    }
    .doc-container{
      background-size: contain;
    }
  }
  &.expandable-shared{
    .cards{
      width:91%;
    }
  }
  .expandable-image{
    width: 91%;
    margin-left: auto;
    margin-right: auto;
    height: 40vh;
    background-size: cover;
    background-position: center;
    border-radius: 0.7rem;
    &.expandable-6{
      height: 35vh;
    }
    &.expandable-5{
      height: 25vh;
    }
    .tooltip-container {
      cursor: pointer;
      position: relative;
      display: inline-block;
      color: white;
      z-index: 999;

      svg {

          g,
          path {
              fill: white;
          }
      }

      button {
          transform: scale(1.7);
          opacity: 0.8;

          &:hover {
              opacity: 1;
          }
      }

      .dropdown {
          height: fit-content;
          visibility: hidden;
          transition: .25s all ease;
          transition-delay: .25s;
          position: absolute;
          margin-top: 5px;
          z-index: 5;
          padding: 8px 0;
          background-color: #050505;
          color: gray;
          border-radius: 6px;
          width: 130px;
          margin-left: -145px;
          margin-top: -25px;

          p {
              font-size: 11px;
              margin:0px;
          }
      }
  }

  .tooltip-container:hover {
      .dropdown {
          visibility: visible;
          transition: .3s all ease;
          transition-delay: 1s;
      }
  }
}

.icons {
  background-color: #0d0d0d9e;
  padding: 10px;
  display: inline-flex;
  flex-direction: column;
  margin: 0 0 0 auto;
  border-radius: 3px;
  position: absolute;
  right: 4.5%;
  z-index: 99;
  gap: 4px;
  justify-content: space-between;
  border-top-right-radius: 10px;

  svg {
      width: 15px;
      transition: 0.5s ease-in-out;
      cursor: pointer;

      g,
      path {
          opacity: 0.8;
      }

      &:hover {

          g,
          path {
              opacity: 1;
          }
      }

      path {
          fill: white;
      }
  }
  }
  .cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 35px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }
  .add-img-circle{
    position: absolute;
    right: -47px;
    top: 20%;
    opacity: 0.7;
    transform: scale(0.85);
    width: 35px;
    font-size: 2rem;
    height: 35px;
    border-radius: 50%;
    color: white;
    background-color: rgba(128, 128, 128, 0.411);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
    &:hover{
      opacity: 1;
    }
  }
  .maincontent {
    width: 100%;
    position: relative;
    .img-plus {
      position: absolute;
      right: -50px;
      top: 10%;
      opacity: 0.4;
      &:hover{
        opacity: 0.6;
      }
    }
    #right, #left{
      transform: scale(0.8);
    }
    #right{
      right: -50px;
    }
    #left{
      left: -50px;
    }
  }
  .hover-text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: 68%;
    margin-top: 8%;
    #Path_549_2_,
    #Path_3 {
      fill: white;
    }
  }
  .hover-text:hover span {
    width: auto;
    padding: 0 8px 0 12px;
    overflow: visible;
  }
  .hover-text:hover {
    .dropdown_container {
      visibility: visible;
    }
    svg {
      #Path_549_2_,
      #Path_3 {
        fill: white;
      }
    }
  }
  .hover-text span {
    color: gray;
  }
  .dropdown_container {
    display: block;
    flex-direction: column;
    transition: all 0.3s linear;
    position: absolute;
    padding: 15px 20px;
    background-color: #050505;
    margin-left: -115px;
    text-align: left;
    line-height: 1.5em;
    color: gray;
    border-radius: 5px;
    margin-top: 120px;
    width: 155px;
    p {
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: white;
      }
    }
  }
  .plus-minus {
    margin-top: 35px;
  }
  .img-plus {
    margin-top: 25px;
    cursor: pointer;
  }
  .minus {
    position: absolute;
    margin-left: -60px;
    margin-top: 8%;
    cursor: pointer;
  }
  .files {
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
  .content {
    margin-left: 0%;
    text-align: left;
  }
  #Layer_1:hover {
    .st0 {
      fill: white;
    }
  }
  &.template3-item{
    padding: 0px;
    .add-img-circle{
      top: 22.5%;
    }
    &:first-child{
      .cards{
        margin-top: 1.2rem;
      }
    }
    &.item-center{
      .add-img-circle{
        top: 25%;
      }
    }
  }
  &.secondrow{
    margin-top: 1rem;
    .add-img-circle{
      top:-19%;
    }
  }
  &.template4-item{
    padding: 0px;

  }
  &.template5-item{
    padding: 0px;
    &:first-child{
      .cards{
        margin-top: 0.5rem;
      }
    }
    .add-img-circle{
      top: 25%;
    }
    &.secondrow{
      margin-top: 1.5rem;
      .add-img-circle{
        top: -26%;
      }
    }
  }
}
.item-center, .template-4, .template-3{
  .cards{
    margin-top: 1rem;
  }
  .add-img-circle{
    top: 22%;
  }
}
  .template-4{
    .cards{
      margin-top: 1.5rem;
    }
    .add-img-circle{
     top: 12%;
    }
  }

.shared-template.template-6 {
  .icons {
    display: none;
  }
}
.template-2 {
  .hover-text {
    margin-left: 71%;
    margin-top: 9%;
  }
  .dropdown_container {
    margin-left: 124px;
  }
}

  // @media screen and (min-width: 1680px) {
  // .shared-template {
  //   margin: 4% auto;
  //   &.portrait-template {
  //     margin: 2% auto;
  //   }
  // }
  // }

// .preview {
//   width: 90%;
//   padding-bottom: 50px;
//   height:100%;
//   @media screen and (min-width: 2000px) {
//       .content{
//           max-width: 1700px;
//       }
//   }
//   @media screen and (min-width: 3000px) {
//       .content{
//           max-width: 2600px;
//       }
//   }
//   @media screen and (max-height: 950px) {
//       .content{
//           max-width: 850px;
//       }
//   }
//   @media screen and (min-width: 1680px) {
//       .content{
//           max-width: 1300px;
//       }
//   }
//   @media screen and (max-height: 722px) {
//       padding-top: 30px;
//       padding-bottom: 30px;
//   }
//   @media screen  and (min-height: 800px) {
//       padding-top: 50px;
//       padding-bottom: 50px;
//   }
//   @media screen  and (min-width: 2560px) {
//       padding-top: 100px;
//       padding-bottom: 100px;
//   }
//   @media screen and (max-height: 650px) {
//       padding-bottom: 20px;
//       padding-top: 20px;
//       .content{
//         width: 100%;
//       }
//       .content{
//           margin-left: 0%;
//       }
//       .template-single-card .main-shared-text h3 p{
//           font-size: 11.5px;
//           span{
//               font-size: 12.5px;
//           }
//       }
//   }
// }

//Slider
.sliderRoom {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}


.bright-mode{
  .shared-template{
    .maincontent{
      #right{
        background-color: rgba(149, 149, 149, 0.648);
        path{
          fill:white;
        }
      }
      #left{
        background-color: rgba(149, 149, 149, 0.648);
      }
    }
  }
  .content-slide{
    color:#FFFFFF;
  }
  .add-img-circle-top{
    color:#FFFFFF;
  }
  .preview-top{
    h2{
      color:black;
    }
  }
} 
// .content-slide{
//   right:23%;
//   font-size: 13px;
//   color: white;
//   top:19px;
//   position: fixed;
//   z-index: 5;
//   &.link-edit{
//     right: 32%;
//   }
// }

  .preview-top{
    color: white;
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity:0.8;
    margin-top: 0px;
    position: relative;
    transform: scale(0.9);
    margin-bottom: -1rem;
    margin-left: -1.5rem;
    min-height: 20px;
    .exitpreview{
      border-bottom: 1px solid white;
    }
    h2{
      font-size:25px;
    }
    a{
      border-bottom: 1px solid white;
      margin-top: -10px;
      color:white;
    }
  }
  .shared-template.previewtemplate{
    // margin-top: 2rem; 
    padding-left: 1rem;
    width: 78%;

    .icons{
      display: none;
    }
  }


  @media screen and (min-height: 581px) {
    .item-center, .template-4, .template-3{
      .cards{
        margin-top: 2rem;
      }
    }
  }
  
 @media(min-height:650px){
      .shared-template{
        transform: none;
        margin-top: 0;
        &.portrait-template{
          .slider-item{
            margin-right: 2.1rem ; 
            min-width: 31% ;
            max-width: 31% ;
            margin-top: 1.6rem;
          }
        }
        &.portrait-template-8{
          .containerSlider #right{
            right: 22%;
            margin-top: 3rem;
          }
          .containerSlider #left{
            left: 0rem;
            margin-top: 3rem;
          }
          &:nth-child(2){
          .slider-item{
            margin-right: 4rem ;
          }
          }
          .slider-item{
            margin-top: 2.6rem;
            min-width: 47% ;
            max-width: 47% ;
          }
        }
       &.portrait-template-10,&.portrait-template-11{
          .template-single-card{
            .image{
              background-position: top;
            }
          }
          .slider-item{
            min-width: 23.2% ;
            max-width: 23.2% ;
            margin-top: 2.6rem;
          }
        }
        &.portrait-template-11{
          width: 70%;
          .containerSlider #right{
            right: 5rem;
          }
          .containerSlider #left{
            left: 5rem;
          }
            .slider-item{
              min-width: 30.5% ;
              max-width: 30.5% ;
              margin-top: 1rem ;
            }
          }
        &.template3-item{
          .add-img-circle{
              top: 26.5%;
          }
          &.item-center{
            .add-img-circle{
              top: 28%;
            }
          }
          &.secondrow{
            .add-img-circle{
              top:-17%;
            }
          }
        }
        &.template5-item{
          &:first-child{
            .cards{
                margin-top: 1.2rem;
            }
          }
          &.secondrow{
            .add-img-circle{
              top:-22%;           
            }
          }
        }
        .maincontent{
          #right, #left{
            transform: scale(1);
          }
          #right{
            right: -50px;
          }
          #left{
            left: -50px;
          }
          // .cards{
          //   width: 104.5%;
          // }
        }
        .previewtemplate{
          // margin-top: 3rem; 
        }
    }
    .item-center{
      .add-img-circle{
        top: 22%;
      }
    }
  }

  @media(min-height:720px){
    .shared-template{
      &.template3-item{
        &:first-child{
          .cards{
              margin-top: 0.2rem;
          }
        }
      }
      .expandable-image{
        width: 96%;
        margin-left: 4%;
      }
      .icons{
        right: 0;
      }
    }
  }

  @media(min-height:722px) and (min-width:1025px){
    .preview-top{
      h2{
        font-size:20px;
        margin-bottom: 0.2rem;
      }
      button{
        font-size: 14px;
      }
    }
    .shared-template{
      .expandable-image{
        &.expandable-7{
          height: 300px;
        }
      }
    }

    
  }

  @media(min-height:800px){
    .shared-template{
      &.template3-item{
        &:first-child{
          .cards{
              margin-top: 2.2rem;
          }
        }
      }
      &.template5-item{
        &:first-child{
          .cards{
              margin-top: 2.2rem;
          }
        }
      }
      .expandable-image{
        margin-bottom: -2.5rem;
      }
    }
    .item-center, .template-4, .template-3, .template-1, .template-2 {
      .cards{
          margin-top: 2rem;
      }
    }
  }

  @media screen and (min-height: 900px) and (min-width: 1280px){
    .shared-template{
      &.portrait-template-11 {
        .maincontent {
          .content{
            margin-left: 19%;
          }
        }
        .cards {
         width: 63%;
        }
      }
  }
}

  @media(min-width:1024px){
    .shared-template{
      .cards{
        flex-wrap: nowrap;
        gap:0px;
      }
    }
    .first-templates{
      .cards {
        margin-top: 2rem;
      }
    }
    .slider-item{
      max-width: 100%!important;
    }
  }
  @media screen and (min-width: 700px) { 
    .content-slide{
      right: 25.5%;
      &.link-edit{
        right: 36%;
      }
    }
  } 
  @media screen and (min-width: 1050px) { 
    .content-slide{
      right: 28%;
      &.link-edit{
        right: 38.5%;
      }
    }
  } 
  @media(min-width:1100px){
    .content-slide{
      right: 30.5%;
      &.link-edit{
        right: 40.5%;
      }
    }
  }
  @media(min-width:1220px){
    .content-slide{
      right: 29%;
      &.link-edit{
        right: 40%;
      }
    }
  }
  @media(min-width:1380px){
    .content-slide{
      right: 31%;
      &.link-edit{
        right: 40%;
      }
    }
  }
  @media(min-width:1500px){
    .content-slide{
      right: 32.5%;
      &.link-edit{
        right: 41%;
      }
    }
  }
  @media(min-width:1600px){
    .content-slide{
      right: 31.2%;
      &.link-edit{
        right: 39.5%;
      }
    }
  }
  @media(min-width:1670px){
    .content-slide{
      right: 33%;
      &.link-edit{
        right: 40.5%;
      }
    }
  }
  @media(min-width:1750px){
    .content-slide{
      right: 34%;
      &.link-edit{
        right: 41.5%;
      }
    }
  }
  @media(min-width:1900px){
    .content-slide{
      right: 36%;
      &.link-edit{
        right: 42.5%;
      }
    }
  }

  @media(min-width:2000px){
    .content-slide{
      right: 37%;
      &.link-edit{
        right: 47%;
      }
    }
  }
  @media(min-width:2100px) {
    .content-slide {
      right: 38%;
    }
  }
  @media screen and (min-width: 1550px) {
    .shared-template.previewtemplate{
      // margin-top: 4.1rem;
    }
    .shared-template{
      &.portrait-template-8{
        .maincontent{
          margin-left: 14%;
        }
      }
    }
    

  }
  @media screen and (min-width: 1800px) {
    .template-2 {
      padding-top: 100px;
    }
    .shared-template{
      &.portrait-template-11{
        .maincontent{
          .content{
            margin-left: 25%;
          }
        }
         .cards{
        width:50%
      }
      }
      .expandable-image{
        &.expandable-7{
          height: 430px;
        }
      }
    }
  }
  @media screen and (min-width: 2560px) {
    .shared-template{
      .maincontent{
        .img-plus, #right, #left, .add-img-circle{
          transform: scale(1.3);
        }
        .img-plus, #right{
          right: -70px;
        }
        #left{
          left: -70px;
        }
        .img-plus{
          top:17%;
        }
      } 
      .expandable-image{
        height: 180px;
        .tooltip-container {
          button {
              transform: scale(2.5);
          }
        }
      }
      .icons{
        padding: 15px;
      }
    }
    .preview-top{
      h2{
        font-size: 35px;
      }
      button{
        font-size: 24px;
      }
    }

    .shared-template.previewtemplate{
      // margin-top: 10.1rem; 
      &.template-3{
        padding-top: 5rem;
      }
    }
    .content-slide{
      right: 35.2%;
      top: 14px;
      font-size: 22px;
      &.link-edit{
        right: 42.5%;
      }
    }
  }


  @media screen and (min-width: 3000px) {
    .shared-template{
      .maincontent{
        .img-plus, #right, #left, .add-img-circle, .tooltip-container button{
          transform: scale(2.0);
        }
        .icons{
          padding: 20px;
        }
        .tooltip-container button{
          transform: scale(4.5);
        }
        .img-plus, #right{
          right: -90px;
        }
        #left{
          left: -90px;
        }
        .content{
          max-width: 1900px;
        }
      }
      .expandable-image{
        height: 380px;
      }
    }
    .content-slide{
      font-size: 31px;
      right: 35%;
      &.link-edit{
        right: 43%;
      }
    }

    .preview-top{
      h2{
        font-size: 49px;
      }
      button{
        font-size: 35px;
      }
    }

    .shared-template.previewtemplate{
      // margin-top: 15.1rem; 
      &.template-3{
        padding-top: 10rem;
      }
    }
  }

  @media screen and (min-width: 4000px) {
    .shared-template{
      .maincontent{
        .img-plus, #right, #left,.add-img-circle, .tooltip-container button{
          transform: scale(5.5);
        }
        .img-plus, #right{
          right: -115px;
        }
        #left{
          left: -115px;
        }
        .content{
          max-width: 2800px;
        }
        .icons{
          padding: 40px;
        }
      }
      .expandable-image{
        height: 480px;
        .tooltip-container{
          .dropdown{
            zoom: 3.2;
            margin-top: -20px;
          }
        }
      }
    }

    .content-slide{
      font-size: 37px;
      right: 36%;
      top: 16px;
      &.link-edit{
        right: 42.5%;
      }
    }

    .shared-template.previewtemplate{
      // margin-top: 10.1rem; 
      &.template-3{
        padding-top: 15rem;
      }
    }
    .preview-top{
      h2{
        font-size: 59px;
      }
      button{
        font-size: 45px;
      }
    }
  }
  .portrait-template-10{
    padding-top: 1.2rem;
    #right{
      margin-top: 7%;
    }
  }
  .previewtemplate{
    padding-left: 1.6rem !important;
    width: 76% !important;
    padding-top: 0;
    .icons{
      display: none !important;
    }
  }