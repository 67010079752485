.fake_nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  > div {
    margin-left: 2px;
    * {
      color: white!important;
      fill: white !important;
      user-select: none !important;
    }
  }

  button {
    cursor: not-allowed !important;
  }

}
