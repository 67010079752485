.template-single-card {
    border-radius: 10px;
    margin-left: 0;
    width: 50%;
   cursor: pointer;
   transition: .2s ease-in-out;
   &:hover{
    transform:translateY(-5px);
    filter: brightness(1.1);
   }
    .mainplaybtn {
        margin-left: 0 !important;
        pointer-events: none;
    }

    .image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 10px;
        aspect-ratio: 16/9;
        background-size: contain;
        display: flex;
        flex-direction: column;
        background-color: #0000005e;
        background-repeat: no-repeat;
        position: relative;
        iframe {
            pointer-events: none;
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }

        .icons {
            background-color: #0d0d0d9e;
            padding: 10px;
            display: inline-flex;
            flex-direction: column;
            margin: 0 0 0 auto;
            border-radius: 3px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 9999;
            gap: 0px;
            padding: 8px;
            justify-content: space-between;
            border-top-right-radius: 10px;

            svg {
                width: 12px;
                height: 12px;
                transition: 0.5s ease-in-out;
                cursor: pointer;

                g,
                path {
                    opacity: 0.8;
                }

                &:hover {

                    g,
                    path {
                        opacity: 1;
                    }
                }

                path {
                    fill: white;
                }
            }
        }
    }

    .image > div {
        position: absolute;
      }

    .show {
        height: 23px;
    }

    .hide {
        display: none;
    }

    .image-overlay {
        position: absolute;
        top: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        color: #fff;
        overflow: hidden;
        text-align: center;
        width: 100%;
        
        -moz-transition: top 0.3s;
        -webkit-transition: top 0.3s;
        transition: top 0.3s;
    }


    .image:hover .image-overlay {
        top: 70%;
    }


    // .microsite-view {
    //     width: 92%;
    // }

    h3 {
        color: rgba(248, 248, 248, 0.731);
        width: 100%;
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        margin-top: 1rem;

        span {
            color: rgba(235, 235, 235, 0.747);
            font-size: 17px;
        }
    }

    .tooltip-container {
        cursor: pointer;
        position: relative;
        display: inline-block;
        color: white;
        z-index: 999;

        svg {

            g,
            path {
                fill: white;
            }
        }

        button {
            transform: scale(1.7);
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }

        .dropdown {
            height: fit-content;
            visibility: hidden;
            transition: .25s all ease;
            transition-delay: .25s;
            overflow: hidden;
            position: absolute;
            margin-top: 5px;
            z-index: 5;
            padding: 8px 0;
            background-color: #050505;
            color: gray;
            border-radius: 6px;
            width: 130px;
            margin-left: -145px;
            margin-top: -25px;

            p {
                font-size: 11px;
                margin: 0px;
            }
        }
    }

    .tooltip-container:hover {
        .dropdown {
            visibility: visible;
            transition: .3s all ease;
            transition-delay: 1s;
        }
    }

    .bright-mode {
        .template-single-card {
            .image {
                .icons {
                    background-color: #cfcfcf84;

                    button {
                        color: rgba(0, 0, 0, 0.642);
                    }

                    svg {

                        g,
                        path {
                            fill: rgb(120, 120, 120) !important;
                        }

                        &:first-child {
                            path {
                                fill: rgb(0, 0, 0) !important;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1300px) {
        .image .icons {
            padding: 10px;
            gap: 4px;


            svg {
                width: 15px;
                height: 15px;
            }
        }
    }

    @media screen and (min-width: 1440px) {
        .image:hover .image-overlay {
            top: 64%;
          }
    }

    // @media screen and (min-width: 1600px) {
    //     .microsite-view {
    //         width: 70%;
    //     }
    // }

    @media screen and (min-width: 2560px) {
        h3 {
            margin-top: 1.5rem;
        }

        .image .icons {
            gap: 10px;
            padding: 15px;

            svg {
                width: 25px;
                height: 25px;
            }
        }

        .microsite-view {
            width: 90%;
        }

        .show {
            height: 35px;
        }

        .image:hover .image-overlay {
            top: 65%;
        }
        
        .tooltip-container {
            button {
                transform: scale(2.5);
            }
        }
    }
    @media screen and (min-width: 3000px) {
        h3 {
            margin: 2rem 0;
        }

        .image .icons {
            gap: 15px;
            padding: 20px;

            svg {
                width: 35px;
                height: 35px;
            }
        }

        .microsite-view {
            width: 90%;
        }
    }

    @media screen and (min-width: 4000px) {
        h3 {
            margin: 3rem 0;
        }

        .image .icons {
            gap: 20px;
            padding: 25px;

            svg {
                width: 45px;
                height: 45px;
            }
        }

        .microsite-view {
            width: 100%;
        }
    }
}