.editor {
  #file-upload {
    display: none;
  }
  .tooltips{
    left: 74%;
    display: inline-table;
    margin-top: -42px;
    position: fixed;
    z-index: 5;
  }
   .tooltiptext {
    visibility: hidden;
    font-size: 13px;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    margin-left: 5px;
  }
  .tooltips .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
  .tooltips:hover .tooltiptext {
    visibility: visible;
  }
  .inner-icons {
    background-color: transparent;
    width: 60%;
    margin: 0 auto;
    border-radius: 16px;
    padding: 18px 20px;
    margin-top: 0px;
    display: flex;
    .icon-preview {
      display: inline-flex;
      align-content: center;
      gap: 10px;
      flex-wrap: wrap;
      svg{
        width: 28px;
        height:28px;
      }
    }
    .icons-edit {
      display: inline-flex;
      width: 100%;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .centered-part {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 24px;
      }
      .borders {
        visibility: hidden;
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        border-right: 1px solid #c8c8c8;
        padding-right: 24px;
        gap: 24px;
        .dropdowns {
          display: inline-flex;
          align-items: center;
          gap: 10px;
          color: white;
        }
      }
      .hover-text {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        button {
          display: flex;
          .hover-icon {
            #Path_549_2_,
            #Path_3 {
              fill: white;
            }
          }
        }
        span {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 1px;
          bottom: 0;
          right: 90%;
          line-height: 40px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          transition: all 0.5s ease 0s;
          white-space: nowrap;
          width: 0;
          display: none;
        }
      }
      .hover-text:hover span {
        width: auto;
        padding: 0 8px 0 12px;
        overflow: visible;
      }
      .hover-text:hover {
        .dropdown_container {
          visibility: visible;
        }
        svg {
          #Path_549_2_,
          #Path_3 {
            fill: white;
          }
        }
      }
      .hover-text span {
        color: gray;
      }
      .prev {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .dropdown_container {
        display: block;
        flex-direction: column;
        visibility: hidden;
        transition: all 0.3s linear;
        overflow: hidden;
        position: absolute;
        padding: 15px 20px;
        background-color: #050505;
        margin-left: -115px;
        text-align: left;
        line-height: 1.5em;
        color: gray;
        border-radius: 5px;
        margin-top: 120px;
        p {
          font-size: 12px;
          cursor: pointer;
          &:hover {
            color: white;
          }
        }
      }
      .preview-button {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        transition: all 0.5s linear;
        .dropdown {
          height: fit-content;
          visibility: hidden;
          transition: all 0.3s linear;
          overflow: hidden;
          position: absolute;
          margin-top: 5px;
          z-index: 5;
          padding: 15px 0;
          background-color: #050505;
          color: gray;
          border-radius: 6px;
          width: 150px;
          p {
            font-size: 14px;
          }
        }
      }
      .tooltiptext {
        font-size: 13px;
      }
      .preview-button:hover {
        .dropdown {
          visibility: visible;
        }
        svg .st0 {
          fill: white;
        }
        p:hover {
          color: white;
        }
      }
    }
  }
  svg {
    width: 20px;
    height: 20px;
    opacity: 0.7;
    cursor: pointer;
  }
  svg:hover {
    opacity: 1;
  }
}

.fixed-editor {
  position: fixed;
  width: 100%;
  z-index: 1;
  margin-top: 20px;
  .inner-icons {
    background-color: #262626f2;
  }
}
.borders-1{
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  border-right: 1px solid #C8C8C8;
  padding-right: 24px;
  gap: 24px;

}

.bright-mode{
  .editor{
    svg, circle, path, rect{
      fill: black !important;
    }
  }
  .tox .tox-tbtn svg{
    fill: #FFFFFF !important;
  }
  .tox .tox-tbtn__select-label{
    color: #FFFFFF !important;
  }
  .borders-1{
    border-right: 1px solid black;
  }
  .tox.tox-tinymce-aux .tox-toolbar__overflow{
    background-color: #DFDFDF !important;
  }
  .tox .tox-menu{
    background-color: #C8CBCF !important;
    color: black !important;
  }
  .tox :not(svg):not(rect){
    color: black;
  }
  .tox .tox-collection--list .tox-collection__item--active{
    background-color: rgba(196, 196, 196, 0.695) !important;
  }
  .tox .tox-tbtn:hover{
    background-color: rgba(196, 196, 196, 0.695) !important;
  }
  .tox .tox-collection--list .tox-collection__item--enabled{
    background-color: rgba(196, 196, 196, 0.695) !important;
  }
  .tox .tox-collection--list .tox-collection__group:first-child{
    background-color: #ebebeb !important;
  }
  .tox .tox-menu{
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.319) !important;
    border: none !important;
  }
  .tox:not([dir=rtl]) .tox-collection--list .tox-collection__item>.tox-collection__item-label:first-child{
    color: black !important;
  }
  .editor .inner-icons .icons-edit .dropdown_container{
    background-color: #e6e5e5 !important;
    color: black !important;
  }
  .editor .inner-icons .icons-edit .dropdown_container p:hover{
    opacity: 0.8 !important;
    color: black !important;
  }
}

.bright-mode{
  .editor{
    .inner-icons{
      .icons-edit{
        .preview-button{
          .dropdown{
            background-color: #e6e5e5 !important;
            p{
              color: black !important;
              &:hover{
                color: black !important;
                opacity: 0.8 ;
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (min-height: 650px) {
  .editor .inner-icons{
    margin-top: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .editor .inner-icons {
    width: 50%;
  }
}
@media screen and (min-width: 1300px) {
  .editor .inner-icons {
    width: 47%;
  }
}
@media screen and (min-width: 1536px) {
  .editor {
    svg {
      width: 28px;
      height: 28px;
    }
    .inner-icons .icons-edit {
      .borders .dropdowns {
        p {
          font-size: 14px;
        }
      }
      .hover-text {
        span {
          display: contents;
        }
      }
    }
  }
}
@media screen and (min-width: 2560px) {
  .editor .inner-icons{
    margin-top: 30px;
    padding: 20px;
    svg{
      transform: scale(1.2);
    }
    .icons-edit .icon-preview{
        gap: 20px;
    }
  }
}
@media screen and (min-width: 3000px) {
  .editor .inner-icons{
    margin-top: 90px;
    padding: 35px 50px;
    .icons-edit .icon-preview{
      gap: 45px;
    }
    svg{
      transform: scale(2);
    }
  }
}
@media screen and (min-width: 4000px) {
  .editor .inner-icons{
    margin-top: 115px;
    padding: 45px 65px;
    .icons-edit .icon-preview{
      gap: 60px;
    }
    svg{
      transform: scale(2.5);
    }
  }
}
