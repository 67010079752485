.doc-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  &:hover{
    .video-cover-item{
      transform: scale(1.1);
    }
  }
 .video-cover-item{
  width: 30px;
  height: 30px;
  transition: .3s ease-in-out;
  border-radius: 50%;
  background-color: #ffffff31;
  backdrop-filter: blur(30px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(226, 226, 226, 0.149);
  position: absolute;
  bottom: 20px;
  right: 20px;
  opacity: 0.7;
  svg{
    color:rgba(255, 255, 255, 0.801);
    font-size: 18px;
    max-width: 12px;
    margin-left: 2px;
  }
 }
  svg {
    width: 5rem;
    height: 5rem;
  }

  h1 {
    font-size: 1.2rem;
    color: white;
    margin-top: 2px;
  }


  .no-content-sign {
    color: rgb(248, 248, 248);
    cursor: pointer;
    svg{
      max-width: 32px;
      g,path{
        stroke: transparent;
        fill: rgb(81, 80, 80);
      }
    }
    .plus-sign {
      font-size: 4rem;
    }
    p {
      font-size: 1.5rem;
      &:hover {
        color: white;
      }
    }
  }


}


.documents-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;

  svg {
    width: 5rem;
    height: 5rem;
  }

  h1 {
    font-size: 1.2rem;
    color: white;
    margin-top: 2px;
  }
}

@media screen and (max-width: 1024px) {
  .doc-container{
    .no-content-sign {
      .plus-sign{
        font-size: 3rem;
      }
      p{
        font-size: 1rem;
      }
    }
  }
}
@media screen and (max-width: 1550px) {
  .doc-container{
    .no-content-sign {
      p{
        font-size: 1.2rem;
      }
    }
  }
}
@media screen and (min-width: 3000px) {
  .doc-container{
    .no-content-sign {
      .plus-sign{
        font-size: 5rem;
      }
      p{
        font-size: 2rem;
      }
    }
  }
}

@media screen and (min-width: 4000px) {
  .doc-container{
    .no-content-sign {
      zoom: 1.6;
    }
  }
}
