.coverpage-container {
    position: relative;
    // padding-top: 2rem;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    .wrapper {
        width: 70%;
        // height: 75vh;
        background-position: center;
        background-repeat: no-repeat;
        // background-image: url('./coverbg3.png');
        background-size: cover;
        margin: 0 auto;
        border-radius: 16px;
        display: flex;
        margin-top: 0px;
        justify-content: center;
        position:relative;
        aspect-ratio: 16/9;
        .firstTemplate {
            width: 90%;
            display: flex;

            .testWrapper {
                width: 40%;
                margin-top: 2%;

                .templateWrapper {
                    padding: 30px 60px;
                    width: 100%;
                    overflow-y: auto;
                    margin-top: 5%;
                    height: 50vh;
                    border-radius: 20px;
                    align-self: 'flex-start'; //to vertically align the blur
                    color: white;
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    justify-content: 'center';
                }

            }
        }
        .plus-icon{
            z-index: 1;
            font-size: 30px;
            cursor: pointer;
            color: white;
            width: 50px;
            height: 50px;
            background: rgba(0,0,0,0.5);
            position: absolute;
            right: 0;
            border-top-right-radius: 16px;
        }
    }

    .microsite-mode {
        margin-top: 20px;
    }

    .secondTemplate {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .testWrapper {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .templateWrapper {
                padding: 30px 60px;
                width: 33%;
                height: 100%;
                border-radius: 20px;
                align-self: 'flex-start';
                color: white;
                text-align: left;
                display: flex;
                flex-direction: column;
                justify-content: 'center';
                overflow-y: auto;
            //       display: flex;
            // justify-content: flex-end;
            }
        }
    }

    .thirdTemplate {
        width: 90%;
        display: flex;
        justify-content: flex-end;

        .testWrapper {
            width: 50%;

            .templateWrapper {
                padding: 50px 60px;
                width: 100%;
                margin-top: 10%;
                height: 75%;
                overflow-y: auto;
                border-radius: 150px;
                align-self: 'flex-start';
                color: white;
                text-align: left;
                display: flex;
                flex-direction: column;
                justify-content: 'center';
            }
        }

    }

    .fourthTemplate {
        width: 80%;
        display: flex;
        justify-content: center;

        .testWrapper {
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding: 4rem 0;
            .templateWrapper {
                padding: 30px 60px;
                width: 100%;
                height: 40%;
                border-radius: 20px;
                color: white;
                text-align: left;
            }
        }


    }

    .fifthTemplate {
        width: 80%;
        display: flex;
        justify-content: flex-start;

        .testWrapper {
            width: 100%;
            display: flex;
            justify-content: flex-start;
        }

        .templateWrapper {
            padding: 30px 60px;
            width: 50%;
            height: 100%;
            border-radius: 20px;
            align-self: 'flex-start'; //to vertically align the blur
            color: white;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            overflow-y: auto;
            padding-top: 120px;
        }
    }

    .sixthTemplate {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .testWrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;

            .templateWrapper {
                padding: 30px 60px;
                width: 100%;
                height: 40%;
                border-radius: 20px;
                margin-bottom: 5%;
                align-self: flex-end;
                color: white;
                text-align: left;
                display: flex;
                overflow-y: auto;
            }
        }
    }

    .seventhTemplate {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .testWrapper {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .templateWrapper {
                padding: 30px 60px;
                width: 50%;
                height: 100%;
                border-radius: 20px;
                align-self: flex-end;
                color: white;
                text-align: left;
                display: flex;
                justify-content: flex-start;
                overflow-y: auto;
            }
        }
    }

    .templateWrapperText {
        height: 100%; 
        display: flex; 
        align-items: center;
    }
}
            .more-buttons {
            gap: 6px;
            display: flex;
            min-width: 100px;
            border-left: 1px solid #ffffff3d;
            padding-left: 15px;
             img,div{
                max-width: 15px;
                max-height: 15px;
             }
            .white-icon {
                background-color: #ffffff;
                width: 100%;
                border-radius: 4px;
            }
            .black-icon {
                background-color: #000000;
                width: 100%;
                border-radius: 4px;
            }
        }

        .buttonsPosition {
            text-align: center;
            display: flex;
            padding-right: 15px;
            .text-position {
                display: inline-flex;
                gap: 3px;
                .color {
                    height: 13px;
                    width: 13px;
                    border-radius: 50%;
                    background-color: #1e1d1d60;
                }
                .color:hover {
                    background-color: #1e1d1d8c;
                }
            }
        }
.backgroundBlur0 {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
}

.backgroundBlur20 {
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.backgroundBlur40 {
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
}

.cardPositionFlexStart {
    justify-content: flex-start !important;
    .testWrapper{
        justify-content: flex-start !important;
    }
    &.fourthTemplate{
  .testWrapper{
    .templateWrapper{
        align-self: flex-start !important;
    }
  }
    }
}

.cardPositionCenter{
    justify-content: center !important;
    .testWrapper{
        justify-content: center !important;
    }
  &.fourthTemplate{
.testWrapper{
    .templateWrapper{
        align-self:center !important;
    }
}
    }
}

.cardPositionFlexEnd {
    justify-content: flex-end !important;
    .testWrapper{
        justify-content: flex-end !important;
    }
    
    &.fourthTemplate{
        .testWrapper{
            .templateWrapper{
                align-self: flex-end !important;
            }
        }
    }
}
.cover-edit-bottom{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.182);
    backdrop-filter: blur(20px);
   border-radius:10px;
   img,div{
    cursor: pointer;
   }
}

@media screen and (max-width: 1024px) {
    .coverpage-container {
        .fifthTemplate {
            .templateWrapper {
                padding-top: 70px;
            }
        }
        .wrapper{
            .firstTemplate{
                .testWrapper{
                    .templateWrapper{
                        height: 21vh;
                    }
                }
            }
        }
        .secondTemplate{
            .testWrapper{
                .templateWrapper{
                    padding: 30px 40px;
                }
            }
        }
        .thirdTemplate{
            .testWrapper{
                .templateWrapper{
                    padding: 10px 60px;
                }
            } 
        }
    }
}

@media screen and (min-height: 600px) {
    .coverpage-container {
        .wrapper {
            .firstTemplate {
                .testWrapper {
                    margin-top: 4%;
                }
            }
        }
    }
}


@media screen and (min-height: 722px) {
    .coverpage-container {
        .wrapper {
            width: 74%;
            .firstTemplate {
                .testWrapper {
                    margin-top: 5%;
                }
            }
        }
    }
}
@media screen and (min-width: 1680px) {
    .coverpage-container {
        .wrapper {
            width: 78%;
        }
    }
}

@media screen and (min-width: 2000px) {
    .coverpage-container {
        .wrapper {
            .firstTemplate {
                .testWrapper {
                    .more-buttons {
                        width: fit-content;

                        .white-icon,
                        .black-icon {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
            }
            .plus-icon{
                width: 65px;
                height: 65px;
                font-size: 40px;
            }
        }
    }
    .coverpage-microsite-icons{
        img{
            width: 20px!important;
            height: 20px;
        }
    }
}
@media screen and (min-width: 2000px) {
    .coverpage-microsite-icons{
        img{
            width: 30px!important;
            height: 30px;
        }
        svg{
            width: 35px!important;
            height: 35px;
        }
    }
}

@media screen and (min-width: 3000px) {
    .coverpage-container {
        .wrapper {
            .firstTemplate {
                .testWrapper {
                    .more-buttons {
                        gap: 8px;

                        .white-icon,
                        .black-icon,
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
            .plus-icon{
                width: 100px;
                height: 100px;
                font-size: 60px;
            }
        }
    }
    .coverpage-microsite-icons{
        top: 6rem;
        zoom: 1.2;
        img{
            width: 35px!important;
            height: 35px;
        }
    }
}
@media screen and (min-width: 3000px) {
    .coverpage-microsite-icons{
        top: 7rem;
        img{
            width:45px!important;
            height: 45px;
        }
    }
}
.purple-mode{
    .coverpage-container .wrapper .firstTemplate .testWrapper .buttonsPosition .text-position .color{
        background: #232d3d;
    }
}
.green-mode{
    .coverpage-container .wrapper .firstTemplate .testWrapper .buttonsPosition .text-position .color{
        background: rgba(31, 52, 47, 0.8941176471);
    }  
}
.blue-mode{
    .coverpage-container .wrapper .firstTemplate .testWrapper .buttonsPosition .text-position .color{
        background: rgba(1, 54, 92, 0.8705882353);
    }  
}
.coverpage-microsite-icons{
    position: absolute;
    right: 1rem;
    top: 4rem;
    z-index: 99999;

    svg{
        width: 25px;
        height: 25px;
        margin: 0 5px;
        opacity: 0.5;
        cursor: pointer;
        &:hover{
            opacity: 1;
        }
        g,path{
            fill: white;
        }
    }
}
.activecoverbg,.activecovertext{
    border: 1.5px solid #ec760071;
}
.activecovertext{
    background-color: #ec760071 !important;
}
@media(max-width:1024px){
    .coverpage-container .wrapper{
        width: 95% !important;
    }
}
@media(max-width:768px){
    .coverpage-container .wrapper{
        width: 100% !important;
        height: 100vh;
        border-radius: 0;
    }
    .coverpage-container{
        .preview-navigation{
            position: absolute !important;
        }
        .children-section{
            margin: 0;
            padding: 0;
        }
    }
    .coverpage-container  .testWrapper{
        width: 100% !important;
        padding-top: 3.5rem;
    }
    .coverpage-container .testWrapper .templateWrapper{
        height: 95% !important;
        margin-top: 2% !important;
        border-radius: 30px !important;
        padding: 10px 30px!important;
    }
}
.remove-bg-button{
    position: absolute;
    right: 10px;
    text-align: center;
    color: white;
    z-index: 9999;
    bottom: 10px;
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
    &:hover{
        p{
            visibility: visible;
        }
    }
    p{
        position: absolute;
        white-space: nowrap;
        margin-right: 35px;
        font-size: 14px;
        background-color: rgba(49, 49, 49, 0.451);
        padding: 4px 12px;
        border-radius: 5px;
        margin-top: -3px;
        visibility: hidden;
    }
}