.filesSingle{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .rpv-core__viewer{
    max-height: 70vh !important;
    .rpv-default-layout__toolbar{
      display: none;
    }
  }
  iframe{
    height: 100%;
  }
  .leftfile,.rightfile{
    position: absolute;
    z-index: 99999;
    cursor: pointer;
    width: 35px;
    height: 35px;
    g,path{
      fill: gray;
    }
    &:hover{
      g,path{
        fill: white;
      }
    }
    .st0{
      fill: transparent !important;
    }
    &:hover{
      .st0{
        fill: transparent !important;
      }
    }
  

  }
  .leftfile{
    left: 0;
  }
  .rightfile{
    right: 0;
  }

  .singlefile{
    margin: 0 auto;
    width: 90% !important;
    aspect-ratio: 16/9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    img,iframe{
      width: 100%;
      border-radius: 8px;
      object-fit: contain;
    }
  }
}
.fileslide-active{
  width: 100%;
  margin-bottom: 10px;
  aspect-ratio: 16/9;
  overflow: hidden;
  .rpv-default-layout__container{
    height: 100% !important;
  }
}
.slider-files{
  display: grid;
  grid-template-columns: repeat(5,1fr);
  width: 100%;
  gap: 15px; 
  .singlefile{
    width: 100%;
    aspect-ratio: 16/9;
  }
  img,iframe{
    width: 100%;
    border-radius: 8px;
    object-fit: contain;
    height: 100%;
  }
}
.main-bottom-slider-container{
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 99;
  .fileslide-active{
    ._3CErh{
      button{
        margin: 0 !important;
        width: 45px !important;
        height: 45px !important;
        display: flex;
        svg{
          margin-left: 5px !important;
        }
      }
    }
  }
  video{
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
  }
  ._3CErh{
    display: flex ;
    align-items: center ;
    justify-content: center ;
    button{
      margin: 0 !important;
      width: 35px !important;
      height: 35px !important;
      display: flex;
      svg{
        margin-left: 4px !important;
      }
    }
  }
  img,iframe{
    width: 100%;
    border-radius: 8px;
    object-fit: contain;
    aspect-ratio: 16/9;
  }
  .singlefile{
    object-fit: cover !important;
    max-width: 240px;
    img,iframe{
      object-fit: cover;
    }
  }
}
.slider-arrows{
  position: fixed;
  overflow: visible;
  z-index: 9;
  width: 100%;
  margin-top: -65px;
  display: flex;
  justify-content: space-between;
  .leftarr{
    margin-left: -50px;
  }
  .rightarr,.leftarr{
    margin-right: -50px;
    &:hover{
      .st0{
        fill: transparent !important;
    }
  }
  }
  svg{
    width: 30px;
    height:30px;
    &:hover{
      .st0{
        fill: transparent;
      }
    }
    .st0{
      fill: transparent;
    }
    g,path{
      fill: white;
    }
  }
}
.slick-list{
  .singlefile{
    width: 97% !important;
    aspect-ratio: 16/9;
    object-fit: cover;
    cursor: pointer;
    border: 1px solid transparent;
    filter:brightness(0.7);
    &:hover{
      opacity: 1;
      filter:brightness(1);
    }
    &.active{
      opacity: 1;
      filter:brightness(1);
      img,frame{
        border: 1px solid rgba(255, 255, 255, 0.352);
      }
    }
  }
}