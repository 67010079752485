.shareContainer {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  *:focus {
    outline: none;
  }
  .backdrop {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.368);
  }

  .shareFrame {
    padding: 1.3rem 2.3rem;
    min-width: 31rem;
    max-width: 31rem;
    /* From https://css.glass */
    background: rgba(123, 123, 123, 0.816);
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.225);
    backdrop-filter: blur(24.7px);
    -webkit-backdrop-filter: blur(24.7px);
    color: white;
    background: #606060b0;
    text-align: left;
    /* From https://css.glass */
    background: rgba(58, 57, 57, 0.816);
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.225);
    backdrop-filter: blur(24.7px);
    -webkit-backdrop-filter: blur(24.7px);
    color: white;
    background: #282828;
    text-align: left;
    font-size: 14px;
    position: absolute;

    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      opacity: 0.9;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    h2 {
      font-weight: 500;
      margin-left: 12px;
    }
    p {
      margin: 15px 0 10px 0;
      font-size: 15px;
    }
    .emails-container {
      background-color: #33333385;
      color: white;
    }
    .inputGroup {
      background-color: #33333385;
      background-color: #333333bd;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      padding-right: 10px;
      align-items: center;
      input {
        background: none;
        border: none;
        color: white;
        padding: 12px;
        font-size: 15px;
        width: 100%;
        &::placeholder {
          color: rgba(255, 255, 255, 0.507);
        }
      }
      svg {
        cursor: pointer;
        &:hover {
          g,
          path {
            fill: white;
          }
        }
      }
    }

    .close-button-container {
      position: absolute;

      bottom: 10px;
      right: 0;
    }
    .iconContainer {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #33333385;
      background-color: #333333e1;
      background-color: #333333bd;
      margin-top: 0.3rem;
      margin-right: 1rem;
      &:hover {
        background-color: #3333339f;
      }
      svg {
        transform: scale(0.9);
        g,
        path {
          fill: white;
        }
      }
    }
  }
}
