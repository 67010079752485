input[type="text"], input[type="password"] {
  outline: none;
  border: 0;
  width: 300px;
  height: 30px;
  background-color: #3b3b3b;
  padding: 0 12px;
  border-radius: 7px;
  opacity: 1;
  color: white;
  letter-spacing: 1px;
}

input {
  outline: none;
  border: 0;
  height: 40px;
  //   background-color: #212121;
  padding-left: 12px;
  border-radius: 7px;
  opacity: 1;
  color: white;
  letter-spacing: 1px;
  width: 90%;
  white-space: nowrap;
  // overflow: hidden;
  text-overflow: ellipsis;
}
input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0 0 30px #dadada0f inset !important;
}
#save-div {
  position: absolute;
  right: 0;
  top: 0;

  button {
    // padding: 10px;
    color: white;
    width: 20px;
    display: flex;

    svg {
      width: 20px;
      height: 20px;

      path,
      g {
        fill: rgba(206, 206, 206, 0.527);
      }

      &:hover {

        path,
        g {
          fill: white;
        }
      }
    }
  }
}

.bright-mode {
  input[type="text"] {
    background-color: #ffffff;
  }

  #save-div {
    svg {

      g,
      path {
        fill: rgba(0, 0, 0, 0.56);
      }

      &:hover {

        g,
        path {
          fill: rgba(0, 0, 0, 0.741);
        }
      }
    }
  }
}

.disabled-btn {
  opacity: 0.5;
  &:hover {
    opacity: 0.2;
  }
}

@media screen and (min-width: 2000px) {
  input{
    font-size: 16px!important;
  }
}
@media screen and (min-width: 2560px) {
  input{
    font-size: 20px!important;
  }
}
@media screen and (min-width: 3000px) {
  input{
    font-size: 30px!important;
  }
}
@media screen and (min-width: 4000px) {
  input{
    font-size: 40px!important;
  }
}

.blue-mode, .purple-mode ,.green-mode{
  input::placeholder{
      color: rgb(255, 255, 255);
    }
}