.container {
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  min-height: 620px;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    max-width: 80%;
    width: 100%;
    padding: 70px 20px;
    color: white;
    margin: 0 auto;

    .imageHolder {
      width: 100%;
      max-width: 70%;

      .image {
        width: 100%;
        object-fit: contain;
        border-radius: 20px;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font: 900 45px/50px Raleway;
        margin-bottom: 30px;
      }

      .desc {
        font: normal 18px/23px Raleway;
        max-width: 700px;
        text-align: center;
        opacity: 0.6;
      }
    }

    &.right {
      flex-direction: row;
    }

    &.left {
      flex-direction: row-reverse;
    }

    &.right,
    &.left,
    &.middle {
      color: black;
      gap: 100px;

      .imageHolder {
        max-width: 50%;
      }

      .text {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .desc {
          max-width: 500px;
          text-align: left;
        }
      }
    }

    &.middle {
      flex-direction: row;
      background-image: url(../../../../assets/images/coversBackground.png);
      background-size: cover;
      background-position: center;
      border-radius: 30px;
      padding: 70px 50px;
      text-align: left;
      .imageHolder{
        max-width: 40%;
      }
    }
  }

  .btn {
    background-color: #444545;
    color: white;
    padding: 5px 15px;
    font-size: 16px;
    border-radius: 30px;
    transition: all 0.4s ease-in-out;
    margin-top: 20px;

    &:hover {
      opacity: 1;
      background-color: rgb(16, 156, 227);
      border-color: rgb(16, 156, 227);
      cursor: pointer;
      transform: scale(1.08);
    }
  }

  @media (max-width: 1199px) {
    min-height: 500px;

    .content {
      max-width: 90%;

      .text {
        .title {
          font: 900 35px/40px Raleway;
        }
      }

      &.middle {
        max-width: 85%;
      }
    }
  }

  @media (max-width: 991px) {
    background-position: left;

    .content {
      .text {
        .title {
          font: 900 30px/35px Raleway;
          margin-bottom: 15px;
        }

        .desc {
          font: normal 16px/22px Raleway;
          margin-bottom: 0;
        }
      }

      &.left,
      &.right,
      &.middle {
        flex-direction: column;
        gap: 40px;

        .text {
          align-items: center;

          .desc {
            text-align: center;
          }
        }

        .imageHolder {
          max-width: 70%;
        }
      }
    }
  }
}

body:global(.purple-mode){
  .container{
    background: rgb(24, 27, 38) !important;
    *{
      color: white;
    }
    .middle{
      background: rgb(59 70 108) ;
    }
  }
}
body:global(.green-mode){
  .container{
    background: rgb(18, 28, 25) !important;
    *{
      color: white;
    }
    .middle{
      background: rgb(46, 73, 65) ;
    }
  }
}
body:global(.blue-mode){
  .container{
    background: rgb(1, 37, 59) !important;
    *{
      color: white;
    }
    .middle{
      background: rgb(8, 73, 114)  ;
    }
  }
}