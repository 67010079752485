.showroom-card-container {
  box-sizing: border-box;
  height: 170px;
  width: 100%;
  position: relative;
  background-color: #2c2c2cb8;
  padding: 20px 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  aspect-ratio: 16/9;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 0;
  &.showroom-card-container-background{
   &::after{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      content: '';
      background-color: #2c2c2ce3;
      border-radius: 6px;
    }
  }


  .showroom-card-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-height: 20px;
    margin: 1% 0;
    width: 100%;

    .template {
      box-sizing: border-box;
      height: 100%;
      margin: 0 1%;
      border-radius: 4px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #0000005e;
    }

    .docImage {
      background-size: cover;
    }

    &.portrait {
      justify-content: center !important;
      max-height: unset;
      min-height: 50%;
      .portraitImg {
        box-sizing: border-box;
        margin: 2px 4px;
        aspect-ratio: 9/16;
        //  min-width: 60px;
        height: 100px;
        border-radius: 4px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #0000005e;

        &.portraitImg3 {
          height: 95px;
          &.docImage{
            height: 62px;
          }
        }
      }

      .docImage {
        background-size: cover;
      }
    }
  }

  .expandable {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 5px;

    &.expandable-template-7 {
      height: 78%;
    }

    .expandableImgHeader {
      width: 100%;
      border-radius: 4px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .expandableRow {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-top: 0.5rem;

      .expandableImg {
        height: 100%;
        margin: 0 1%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #0000005e;
        border-radius: 4px;
        aspect-ratio: 16/9;
      }

      .docImage {
        background-size: cover;
      }
    }
  }
}

.template-thumbnail-4 {
  height: 35px !important;
  margin: 0px 3px !important;
  aspect-ratio: 16/9;
}

.expandable-thumbnail-img3 {
  height: 46px !important;
  aspect-ratio: 16/9;

}
.template-thumbnail-3 {
  height: 47px !important;
  aspect-ratio: 16/9;
}

.template-thumbnail-2 {
  aspect-ratio: 16/9;
}

.template-thumbnail-1 {
  height: 100% !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  aspect-ratio: 16/9;
}

.expandable-thumbnail-img5 {
  height: 26.6px !important;
}
.expandable-template-5 {
  .expandable-thumbnail-img2 {
    height: 71.5px !important;
  }
}

.showroom-card-container4 {

  .showroom-card-row {
    margin: 3.5% 0;
  }
}

.blue-mode {
  .showroom-card-container {
    background-color: #104971;
    &::after{
      background-color: unset;
    }
  }
}

.purple-mode {
  .showroom-card-container {
    background-color: #2d3542;
    &::after{
      background-color: unset;
    }
  }
}

.green-mode {
  .showroom-card-container {
    background-color: #144236;
    &::after{
      background-color: unset;
    }
  }
}

.bright-mode {
  .showroom-card-container {
    background-color: #dedede;
    &::after{
      background-color: unset;
    }
  }
}

.headerexp5{
  margin-top: -.8rem;
}
.showroom-card-container3{
  .showroom-card-row{
    max-height: 40px;
    &.portrait{
      width: 60%;
      justify-content: flex-start !important;
      margin: 5px auto;
    }
  }
}
.showroom-card-container4{
  .showroom-card-row{
    max-height: 25px;
  }
}
.portraitImg11{
  height: 62px !important;
}
.single-card-thumb-12{
  max-height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &::after{
    position: absolute;
    width: 65%;
    height: 5px;
    z-index: 1;
    bottom: 10px;
    content: '';
    background-image: url("https://cdn.cf.masterwizr.com/Rectangle_15277_m6ydjr.png");
    background-size: contain;
  }
}
.single-card-thumb-13{
  max-height: 70% !important;
  &::after{
    position: absolute;
    right: 5%;
    width: 30%;
    height: 50px;
    z-index: 1;
    content: '';
    background-image: url("https://cdn.cf.masterwizr.com/Union_fys4ks.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.single-card-thumb-14{
  max-height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.showroom14-arrows{
  position: absolute;
  opacity: 0.3;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80%;
  svg{
    width: 18px;
    height: 18px;
    .st0{
      fill: transparent;
    }
  }
}
.single-card-thumb-15{
  max-height: 70% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.single-card-thumb-15{
  margin-top: -2rem !important;
}
.showroom-bottom-thumbnails{
  width: 100%;  
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  .lazy-load-image-background{
    width: 100% ;
    aspect-ratio: 16/9;
    width: 100%;
    img,iframe{
      width: 100%;
      border-radius: 3px;
      aspect-ratio: 16/9;
      object-fit: cover;
      object-position: center;
    }
  }
}
.bottom-thumbnails{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  width: 60%;
  gap: 10px;
  position: absolute;
  bottom: 17px;
}
.vertical-thumbnail-16{
  width: auto !important;
  aspect-ratio: 16/9;
  max-height: 80% !important;
}
.vertical-arrows{
  margin-left: 15px;
  svg{
    width: 15px;
    height: 15px;
    opacity: 0.5;
    margin: 5px 0;
    transform: rotate(90deg);
  }
}