@import "../../.././../assets/scss/_mixins";
.datepicker-wrapper {
  width: 100%;
  display: flex !important;

  input {
    height: 26px;
    width: fit-content !important;
    background: transparent;
    color: #cbcbcb;
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
    border: none;
    background: #5353531e;
    width: 95% !important;
  }
  .from-to{
    color: lightgray;
    margin: 0px 10px 0px 3px;
  }
}
