.shared-popup{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    backdrop-filter: blur(26px);
    -webkit-backdrop-filter: blur(26px);
    background-color: rgba(0, 0, 0, 0.576);
    .backdrop{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
       content: '';
    }
    .popup-frame{
        padding: 1.2em 1.2rem;
        background: #2E2E2E 0% 0% no-repeat padding-box;
        border-radius: 18px;
        max-height: 100%;
        display: inline-grid;
        p{
            font-size: 16px;
            color:#CCCCCC;
        }
        h2{
            font-size: 17px;
        }
        .list {
            max-height: 94%;
            overflow: scroll;
        }
        label {
            font-size: 13px;
        }
        button{
            font-size: 14px;
        }
        .options-container{
            padding: 1rem 2.5rem;
        }
    }

    @media screen and (max-height: 768px) {
        .popup-frame {
          zoom: 0.75;
        }
      }
    .buttons-popup{
        display: flex;
        gap: 25px;
        width: 100%;
        justify-content: center;
        margin-top: 1rem;
        button{
            padding: 5px 20px;
            border-radius: 30px;
            &:first-child{
                background-color: rgb(68, 68, 68);
            }
        }
    }
    .yes{
        background-color: #FC2121;
        color: white;
        height: 35px;
        border-radius: 5px;
        cursor: pointer;
        display: inline-block;
        transition: all .3s;
        position: relative;
        width: 136px;
        overflow: hidden;
        z-index: 1;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -2;
        }
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: darken(#FC2121,8%);
            transition: all .3s;
            z-index: -1;
        }
        &:hover {
            &:before {
                width: 100%;
            }
        }
    }
    @media screen and (min-height: 900px) {
        .popup-frame{
            padding:1.5em 1.5rem;
            .options-container{
                padding: 1rem 3rem;
            }
            h2{
                font-size: 19px;
            }
            label {
                font-size: 13px;
            }
            button{
                font-size: 15px;
            }
        }
    }
    @media screen and (min-width: 2560px) {
        .popup-frame{
            padding: 2.0em 2.0rem;
            max-width: 750px!important;
            p{
                font-size: 28px;
            }
            input[type="text"]{
                height:45px;
                margin-left: 15px;
            }
            .center-content{
                gap:20px;
            }
            button{
                width: 170px;
                font-size: 20px;
                height: 45px;
            }
            .buttons-popup{
                margin-top: 15px;
            }
        }
    }
    @media screen and (min-width: 3000px) {
        .popup-frame{
            max-width: 1200px !important;
            padding:3em 3rem;
            p{
                font-size: 40px;
            }
            .name{
                gap: 30px;
                p{
                    width: 115px;
                }
            }
            input[type="text"]{
                height:55px;
            }
            button{
                width: 270px;
                font-size: 35px;
                height: 70px;
            }
        }
    }
    @media screen and (min-width: 4000px) {
        .popup-frame{
            max-width: 1400px !important;
            padding: 3.5em 2.5rem;
            p{
                font-size: 49px;
            }
            .name{
                gap: 40px;
                p{
                    width: 140px;
                }
            }
            input[type="text"]{
                height:60px;
            }
            .center-content{
                gap:50px;
            }
            .buttons{
                gap:40px;
            }
        }
    }
}

.bright-mode{
    .popup-frame{
        background-color: white;
        input{
            background-color: rgb(234, 234, 234)!important;
        }
        .title{
            color: #000000!important;
        }
        h2{
            color:black;
        }
        label{
            color: #000000;
        }
        p{
            color: #191919e8;
        }
        span{
            color: #191919e8!important;
        }
    }
}