.slider-fixed-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  background-color: black;

  .slider-popup-container {
    position: relative;
    height: inherit;
    width: inherit;
    display: flex;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;

    .non-img {
      position: absolute;
      left: 50%;
      top: 20px !important;
      transform: translateX(-50%);
      width: 100%;
      height: 93%;
      
      iframe {
        width: 100%;
        height: 97%;
        border-radius: 10px;
        z-index: 1;
      }

      &.video-player-slider {
        padding: 0 5px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .rightcontrols{
          div{
            &:nth-child(2){
              display: none;
            }
          }
        }
        .mw-videocontainer,video{
          width: 80%;
          margin: 0 auto;
          border-radius: 10px;
        }
        .video-player-slider-backdrop{
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          z-index: 1;
          background-size: cover;
          filter: blur(30px) brightness(0.8);
          .mainplaybtn{
            display: none;
          }
          video{
            max-width: 100vw;
            width: 100vw;
            height: 100vh;
            object-fit: fill;
            object-position: center;
          }
        }
        &::after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
          border-radius: 10px;
        }

        & * button {
          width: 60px;
          height: 60px;
        }
      }
      &.video-slider-player-full{
        .mw-videocontainer,video{
          width: 100%;
        }
      }
      &.document-player-slider {
        width: 100% !important;
        height: 100% !important;
        display: flex;
        justify-content: center;
        iframe {
          border-radius: 10px;
          width: 40% !important;
          min-width: 400px !important;
          height: calc(100% - 80px) !important;
          margin-top: 10px;
        }
      }
    }

    .arrow-container {
      width: 8%;
      height: calc(100% - 50px);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      button {
        display: none;
        width: 44px;
        border-radius: 50%;

        svg {
          height: 100%;
          width: 100%;

          circle {
            fill: rgba(62, 62, 62, 0.267);
          }

          .st1 {
            fill: rgba(123, 123, 123, 0.536);
          }

          path {
            fill: #434343;
            stroke: #303030;
          }
        }

      }

      &:hover button {
        display: block;
      }
    }

    .slider-footer-control {
      position: absolute;
      bottom: 0px;
      width: inherit;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;

      .slider-logo {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-bottom: 18px;

        .slider-pagination {
          // background-color: rgb(0 0 0 / 29%);
          padding: 5px 30px 5px;
          border-radius: 30px;
          font-size: 1rem;
          font-weight: bold;
          margin: 0px 5px;
          background: linear-gradient(to bottom, rgba(22, 22, 22, 0.2156862745), rgba(31, 31, 31, 0.3333333333), rgba(39, 39, 39, 0.3411764706), rgba(49, 49, 49, 0.3019607843), rgba(58, 58, 58, 0.3333333333));
        }

        .mw-logo {
          font-size: 0.8rem;
          margin: 0;

          a {
            color: #fff;
            margin: 0 5px;
          }
        }
      }

      .slider-control {
        margin-right: 25px;
        display: flex;
        margin-bottom: 18px;
        z-index: 5;

        .svg-container {
          position: relative;
          .svgOn{
            path{
              fill: #EB7500!important;
            }
          }
          .timeoutText{
            position:absolute;
            top: 0%;
            color: #ffffffa6;
            background-color: #292929;
            width: 315px;
            right: 93%;
            font-size:13px;
            padding:7px 10px;
            border-radius:8px;
          }
          .slider-action-indicator {
            background-color: #292929;
            border-radius: 8px;
            bottom: 25px;
            display: flex;
            max-width: --webkit-max-content;
            max-width: max-content;
            padding: 7px 15px;
            position: absolute;
            right: 5px;
            visibility: hidden;
            width: 300px;
            color: hsla(0, 0%, 100%, .651);
            font-size: 12px;
          }

          &:hover .slider-action-indicator {
            visibility: visible;
          }
        }

        svg {
          cursor: pointer;
          width: 30px;
          height: 30px;
          margin: 0 7px;
          background: linear-gradient(to bottom, rgba(22, 22, 22, 0.2156862745), rgba(31, 31, 31, 0.3333333333), rgba(39, 39, 39, 0.3411764706), rgba(49, 49, 49, 0.3019607843), rgba(58, 58, 58, 0.3333333333));
          border-radius: 50%;

          g,
          path {
            fill: white;
          }
        }
      }
    }

    .slider-img-hovering-container {
      position: fixed;
      bottom: 0;
      height: 120px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 920px !important;
      z-index: 4;
      opacity: 0.7;

      .slider-images {
        box-sizing: unset;
        position: absolute;
        left: 50%;
        bottom: -100px;
        height: 70px;
        border-radius: 10px;
        padding: 0 5px;

        &.open {
          animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

          .splide {
            width: 100%;
            max-width: 920px !important;

            ul {
              li {
                cursor: grab;
                width: 110px !important;
                border-radius: 10px;

                .video-thumbnail {
                  width: 100%;
                  height: 70px;
                  border-radius: 10px;
                  border: 2px solid grey;

                  iframe {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    pointer-events: none;
                    z-index: -1;
                  }

                  &.active {
                    border: 2px solid #ec6700;
                    opacity: 1;
                  }
                  & * button {
                    transform: scale(0.7);
                    transform-origin: -100% -100%;
                    pointer-events: none;
                  }
                }

                img {
                  width: 100%;
                  height: 70px;
                  border-radius: 10px;
                  border: 2px solid grey;
                  &.active {
                    border: 2px solid #ec6700;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slide-top {
  0% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, -130px);
  }
}

.rpv-default-layout__toolbar {
  background-color: transparent !important;
  border: none !important;
}

.rpv-default-layout__container {
  height: 100vh;
  margin-left: -10px;
  width: 101% !important;
  margin-top: -25px;


  .rpv-default-layout__main {
    .rpv-default-layout__body {
      border-top-left-radius: 4rem;
      padding-top: 4rem;

      &::-webkit-scrollbar {
        width: 1em;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba(245, 245, 245, 0.096);
        border: none;
        outline: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(212, 212, 212, 0.473);
        border: none;
      }
    }
  }
}

.rpv-default-layout__toolbar {
  background-color: white;
  border-bottom: none;
  padding-left: 20px;
  height: 50px;
  width: 100%;

  .rpv-zoom__popover-target-arrow{
    border-width: .5rem .25rem 0 !important;
  }

  span {
    color: #dadada;
  }

  .rpv-toolbar {
    margin-top: 10px;

    .rpv-toolbar__right {
      .rpv-core__display--hidden {
        &:first-child {
          display: none !important;
        }
      }
    }
  }
}

div[data-testid="core__inner-pages"] {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(245, 245, 245, 0.096);
    border: none;
    outline: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: green;
    background-color: rgba(212, 212, 212, 0.473);
    border: none;
  }
}

.rpv-default-layout__sidebar-headers,
.rpv-toolbar__center,
button[data-testid="open__button"] {
  display: none;
}

.rpv-core__textbox {
  width: 25px;
}

button[data-testid="full-screen__enter-button"] {
  display: none !important;
}

button[data-testid="theme__switch-button"],
button[data-testid="full-screen__enter-button"],
button[data-testid="open__button"],
button[data-testid="get-file__download-button"],
button[data-testid="print__button"],
button[data-testid="search__popover-button"],
button[data-testid="page-navigation__next-button"],
button[data-testid="full-screen__enter-button"],
button[data-testid="page-navigation__previous-button"] {
  max-width: 24px !important;
  margin: 0 2px !important;

  svg {
    width: 15px !important;
  }
}

button[data-testid="page-navigation__previous-button"] {
  margin-left: 10px;
}

.rpv-page-navigation__current-page-input {
  width: 1.5rem !important;
}

button[data-testid="page-navigation__next-button"],
button[data-testid="page-navigation__previous-button"] {
  transform: scale(0.8) !important;
}

.rpv-page-navigation__current-page-input {
  input[type=text] {
    background: none;
    color: black;
  }
}

@media screen and (min-width: 2000px) {
  .slider-fixed-popup .slider-popup-container .slider-footer-control{
    .slider-control{
      transform: scale(1.2);
    }
    .slider-logo{
      .mw-logo{
        font-size: 1.2rem;
      }
      .slider-pagination{
        font-size: 1.2rem;
      }
    }
  }
}
@media screen and (min-width: 3000px) {
  .slider-fixed-popup .slider-popup-container .slider-footer-control{
    bottom: 20px;
    .slider-control{
      transform: scale(2);
      margin-right: 65px;
      margin-bottom: 25px;
    }
    .slider-logo{
      .mw-logo{
        font-size: 2rem;
      }
      .slider-pagination{
        font-size: 2rem;
      }
    }
  }
}
@media screen and (min-width: 4000px) {
  .slider-fixed-popup .slider-popup-container .slider-footer-control{
    .slider-control{
      transform: scale(2.2);
      margin-right: 90px;
    }
    .slider-logo{
      .mw-logo{
        font-size: 2.2rem;
      }
      .slider-pagination{
        font-size: 2.2rem;
      }
    }
  }
}
.nextPresBtn{
  position: fixed;
  top: 10px;
  right: 20px;
  color: white;
  z-index: 9999;
  font-size: 16px;
  padding:5px 18px;
  opacity: 0.8;
  background-color: #9e9e9e5a;
  border-radius: 5px;
  backdrop-filter: blur(50px);
  &:hover{
    opacity: 1;
  }
  svg{
    transform: rotate(-90deg);
    margin-left: 5px;
  }
}
.fullscreen-slider-video{
position: absolute;
right: 11%;
top: 10px;
z-index: 999999;
width: 80%;
opacity: 0.2;
&:hover{
  opacity: 1;
}
svg{
  width: 25px;
  height: 25px;
  background-color: rgba(226, 226, 226, 0.239);
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  g,path{
    fill: white;
  }
}
}
.video-player-container{
  position: relative;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.video-slider-player-full{
  .video-player-container{
    width: 98vw;
    height: 95vh;
    padding-bottom: 5vh;
  }
  .fullscreen-slider-video{
    right: 10px;
    top: 20px;
  }
}