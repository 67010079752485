.container {
  width: 100%;
  padding: 5px 0;
  background: linear-gradient(90deg, #A45302 3.36%, #EC7600 56.35%, #A45302 94.9%);
  display: flex;
  align-items: center;
  justify-content: center;  
}

@media (max-width: 575px) {
  .container{
    padding: 10px 0;
  }
}