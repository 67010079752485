.faqSection {
  color: rgba(255, 255, 255, 0.923);
  max-width: 70%;
  margin: 0 auto;
  padding: 50px 0 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .faq {
    font: normal 20px/35px Raleway;
  }

  .desc {
    font: 900 40px/45px Raleway;
    margin-bottom: 50px;
  }

  .faqItem {
    width: 100%;
    cursor: pointer;
    padding-bottom: 35px;
    margin-bottom: 20px;
    transition: background-color 0.3s;
    text-align: left;
    position: relative;
    overflow: hidden;
    border-bottom: 0.7px solid rgba(255, 255, 255, 0.539);
    height: 25px;
    transition: height 0.5s ease-out;

    .question {
      display: flex;
      justify-content: space-between;

      .title {
        font: normal 22px/25px Raleway;
      }
    }

    .answer {
      display: block;
      font: normal 18px/25px Raleway;
    }

    &:focus {
      outline: none;
    }
  }
}

.faqItem.active {
  height: 100px;
  transition: height 0.5s ease-out;
}

@media (max-width: 1199px) {
  .faqSection {
    .desc {
      font: 900 30px/35px Raleway;
    }

    .faqItem {
      .question {
        .title {
          font: normal 20px/25px Raleway;
        }
      }

      .answer {
        font: normal 16px/20px Raleway;
      }
    }
  }
}

@media (max-width: 991px) {
  .faqSection {
    max-width: 80%;

    .answer {
      font: normal 16px/22px Raleway;
    }
  }
}

@media (max-width: 767px) {
  .faqSection {
    padding: 50px 0;

    .faq {
      font: normal 18px/25px Raleway;
    }

    .desc {
      font: 900 25px/30px Raleway;
    }

    .faqItem {
      padding-bottom: 12px;
      margin-bottom: 12px;

      .question {
        .title {
          font: normal 18px/22px Raleway;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .faqSection {
    padding: 30px 0;

    .desc {
      font: 900 20px/25px Raleway;
      margin-bottom: 35px;
    }

    .faqItem {
      .question {
        svg {
          zoom: 0.8;
        }

        .title {
          font-size: 16px;
        }
      }

      .answer {
        font: normal 14px/18px Raleway;
      }
    }
  }
}
