.container {
  padding: 4rem;
  margin: 0 auto;
  background-image: url("https://cdn.cf.masterwizr.com/Rectangle_22_gybn5m.jpg");
  background-size: 290%;
  background-position: bottom left;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .actionBtn {
    padding: 8px 30px;
    border-radius: 30px;
    color: white;
    background-color: rgba(128, 128, 128, 0.188);
  }

  .title {
    background-image: linear-gradient(
      to right,
      #38ad93,
      #476498,
      #e46a20,
      #1d79bb
    );
    -webkit-background-clip: text;
    color: transparent;
    margin: 0;
    margin-bottom: 15px;
    font-size: 6rem;
    font-weight: bolder;
  }

  .data {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 80%;
    margin: 2rem auto;
    gap: 20px;
    margin-top: 5rem;

    .card {
      padding: 2rem;
      border-radius: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: rgba(108, 108, 108, 0.123);
      backdrop-filter: blur(50px);

      h3 {
        margin-top: 2rem;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.718);
      }

      p {
        margin-top: 1rem;
        opacity: 0.7;
      }

      img {
        width: 90%;
      }
    }
  }
}

@media (max-width: 1550px) {
  .container {
    .title {
      font-size: 4rem;
    }
    .actionBtn {
      padding: 5px 26px;
      font-size: 15px;
    }
    .data {
      margin: 2rem auto;
      width: 100%;

      .card {
        p {
          font-size: 12px;
        }

        h3 {
          font-size: 1.2rem;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .container {
    padding: 2rem;
    padding-top: 5rem;

    .title {
      font-size: 3rem;
    }

    .data {
      margin: 2rem auto;
      width: 95%;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.magazineParentButton {
  width: 100%;
  z-index: 999;
  visibility: visible;
  position: fixed;
  bottom: 11%;
  margin: auto;
  .magazineButton {
    font-size: 20px;
    visibility: visible;
    background-color: #ec7600;
    font-weight: bold;
    color: white;
    padding: 6px 25px;
    border-radius: 20px;
  }
}


@media screen and (min-width:768px) {
  .magazineParentButton {
    .magazineButton {
      display: none;
    }
  }
}