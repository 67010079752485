.imgmodal {
    min-height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    &.infomodal{
        background-size: cover;
        background-position: center;
        background-image: url("https://cdn.cf.masterwizr.com/Frame_1321316415_c9uvla.jpg");
        .backdrop{
            background-color: transparent;
        }
        .frame{
            background: #e2e2e254;
            backdrop-filter: blur(5px);
            max-width: 80rem;
            padding-top: 10px;
            max-height: 60rem;
            .imagepart{
                display: none;
            }
        }
    }
    &.mpublisherModal{
        background-size: cover;
        background-position: center;
        background-image: url("https://cdn.cf.masterwizr.com/Rectangle_42168_vw7fyg.png");
        .backdrop{
            background-color: transparent;
        }
        .frame{
            background: #12121261;
            backdrop-filter: blur(5px);
            max-width: 80rem;
            padding-top: 10px;
            max-height: 60rem;
            .imagepart{
                display: none;
            }
        } 
    }
    *:focus {
      outline: none;
    }
    .backdrop {
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      background-color: rgba(0, 0, 0, 0.905);
    }
    .frame{
        min-width: 40rem;
        max-width: 40rem;
        min-height: 32.3rem;
        max-height:32.3rem ;
        position: relative;
        color: white;
        background: #282828;
        padding: 2rem 4rem;
        border-radius: 10px;
        padding-top: 60px;
        text-align: left;
        width: 100%;
        p{
            margin: 0;
        }
        .title{
            margin: 10px 0;
            font-size: 18px;
            color: white;
        }
        .text{
            margin: 10px 0;
            text-align: left;
            margin-bottom: 40px;
            opacity: 0.6;
            font-size: 14px;
        }
        button{
            padding: 5px 25px;
            border-radius: 5px;
            color: white !important;
            font-size: 14px;
            margin: 0 8px;
            &:first-child{
                background-color: rgb(62, 62, 62);
            }
            &:nth-child(2){
                background-color: #ec7600;
            }
        }
        .imagepart{
            width: 100%;
            height: 45px;
            background-image: url(../../../../assets/images/modalbg3.jpeg);
            background-position: center;
            background-size: cover;
            position: absolute;
            top: 0;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.225);
            left: 0;
            z-index: 1;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
    }
    &.upgrade-popup{
        h1{
            color: white;
            font-size: 18px;
            margin-bottom: 20px;
        }
        li{
            margin: 10px 0;
            font-size: 14px;
        }
    }
    &.sharingpart{
        .frame{
            .title, .text{
                margin: 0;
            }
            .imagepart{
                background-image: none;
                box-shadow: none;
                &:before{
                    background-color: transparent;
                }
            }
            p{
                font-weight: 550;
            }
            .input-copylink p{
                font-weight: normal;
            }
        }
    }
    &.sharing-first-modal, &.sharingpart{
        .frame{
            padding:2rem 4rem 6rem 4rem;
            min-height: 100%;
            max-height: 100%;
        }
    }
    &.sharing-first-modal{
        input[type=checkbox]{
            height: 20px;
            width: 20px!important;
            border-radius: 3px;
            padding-left: 0;
        }
        input[type=checkbox], input[type=checkbox]:checked {
            -moz-appearance:none;
            -webkit-appearance:none;
            -o-appearance:none;
          }
        input[type=checkbox]:after {
            content: " ";
            border-radius: 3px;
            display: inline-block;
            visibility: visible;
          }
          
          input[type=checkbox]:checked:after {
            background: #ec7600;
            height: 20px;
            width: 20px;
            display: block;
            text-align: center;
            color: white;
          }
    }
}

@media screen and (max-width: 768px){
    .imgmodal {
        .frame{
            min-width: unset;
            zoom: 0.65;
        }
        &.sharing-first-modal, &.sharingpart{
            .frame{
                padding:1rem 2rem 3rem 2rem;
            }
        }
    }
}

.sharing-secondstep{
    .frame{
        .text{
            margin-bottom: 0;
            max-width:71%;
        }
    }
}
.sharing-first-modal{
    .frame{
        input{
            background-color: #3b3b3b75;
            font-size: 13px !important;
            width: 100% !important;
            &::placeholder{
            font-size: 13px !important;
            color: rgba(255, 255, 255, 0.507);
            }
        }
        .text{
            margin-bottom: 0;
            max-width:73%;
        }
        p{
            margin-top: 1.5rem;
            opacity: 0.8;
            margin-bottom: 10px;
            font-weight: 550;
        }
    }
}
.savemodal{
   .frame{
    .imagepart{
        background-image: url(../../../../assets/images/modalbg2.png);
        background-position: bottom ;
    }
   }
}
.microsite-create-modal{
  .frame{
         max-height:none;
         min-width: 47rem;
         min-height: 37rem;
          padding: 1rem 3.5rem 5rem 3.7rem;
         h1{
             font-size: 1.9rem;
             text-align: center;
             color: white;
             margin-top: 10px;
             margin-bottom: -5px;
             opacity: 0.7;
             &:hover{
                 opacity: 1;
             }
         }
         .imagepart{
         display: none;
      }
     }
 }

@media (min-width: 2560px) and (min-height: 1440px) {
    .imgmodal{
        .frame{
            zoom: 1.3;
                input{
                    font-size: 16px !important;
                }
                .text{
                    font-size: 16px;
                }
                .title{
                    font-size: 20px;
                }
                span{
                    font-size: 16px;
                }
        }
        &.upgrade-popup{
            .frame{
                max-height: 34rem;
            }
            li{
                font-size: 16px;
            }
        }
    }
}
@media (min-width: 3840px) and (min-height: 2160px) {
    .imgmodal{
        .frame{
            zoom: 1.8;
            input{
                &::placeholder{
                    font-size: 16px !important;
                }
            }
            .text{
                font-size: 20px;
                margin: 20px 0 40px;
            }
            .title{
                font-size: 24px;
            }
            p{
                font-size: 20px;
            }
            .inputGroup{
                font-size: 22px !important;
            }
            span{
                font-size: 20px;
            }
        }
        &.upgrade-popup{
            .frame{
                max-height: 38rem;
            }
            li{
                font-size: 20px;
            }
        }
        &.microsite-create-modal{
            .frame{
                h1{
                font-size: 2.6rem;
                }
                p{
                    font-size: 22px;
                }
            }
        }
    }
}

@media (min-width: 4000px)  {
    .imgmodal{
        .frame{
            zoom: 2.2;
            input{
                font-size: 18px !important;
                &::placeholder{
                    font-size: 18px !important;
                }
            }
        }
        &.sharing-first-modal, &.sharingpart{
            .frame{
                zoom: 2.4;
                p{
                     font-size: 22px;
                }
            }
        }
    }
}