.shareContainer {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  button{
    background-color: transparent !important;
  }
  input{
    width: 100% !important;
    &::placeholder {
      color: rgba(255, 255, 255, 0.507);
    }
  }
  *:focus {
    outline: none;
  }
  .bottombtn{
    margin-top: 5rem;
  }
  .backdrop {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.368);
    backdrop-filter: blur(26px);
    -webkit-backdrop-filter: blur(26px);  
    }
  .shareFrame {
    padding: 1.3rem 2.3rem;
    min-width: 31rem;
    max-width: 31rem;
    /* From https://css.glass */
    background: rgba(123, 123, 123, 0.816);
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.225);
    backdrop-filter: blur(24.7px);
    -webkit-backdrop-filter: blur(24.7px);
    color: white;
    background: #292929e3;
    text-align: left;
    font-size: 14px;
    position: absolute;
    .schedule{
      margin: 2rem 0;
    }
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      opacity: 0.9;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    h2 {
      font-weight: 500;
      margin-left: 12px;
    }
    p {
      margin: 15px 0 10px 0;
      font-size: 15px;
    }
    span{
      font-size: 13px;
      color: rgb(223, 223, 223);
    }
    .emails-container{
        background-color: #33333385;
        color: white;
    }
    .inputGroup {
      background-color: #33333385;
      background-color: #5050504f;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      padding-right: 10px;
      width: 100%;
      align-items: center;

      input {
        background: none;
        border: none;
        color: white;
        padding: 12px;
        font-size: 12px;
        width: 100%;
        &::placeholder {
          color: rgba(255, 255, 255, 0.507);
        }
      }
      svg {
        cursor: pointer;
        &:hover {
          g,
          path {
            fill: white;
          }
        }
      }
    }
  }
}
.expirylabel{
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 0rem;
  gap:10px;
  p{
    font-size:14px;
    margin-top:0.7rem!important;
  }
}
.inputbutton{
  display: flex;
  align-items: center;
  input{
    width: 100% !important;
    font-size: 12px;
    padding: 12px;
  }
  p{
    text-align: right;
    color: #ec7600;
    font-weight: normal!important;
    text-align: left;
    margin-left: 18px !important;
    font-size: 15px;
    white-space: nowrap;
    width: 20%;
  }
}
.bottombtn{
  position: absolute;
  display: flex;
  justify-content: flex-end;
  bottom: 1.1rem;
  right: 2rem;
  button{
    background: transparent !important;
    max-width: max-content;
    white-space: nowrap;
    min-width: max-content;
    margin: 0;
    padding: 10px !important;
    opacity: 0.7;
    font-size: 16px;
    font-weight: 500;
    &:hover{
      opacity: 1;
      background: none;
      border: none;
    }
    &:last-child{
      color: #ec7600 !important;
      font-weight: 600;
    }
    &:first-child{
      color: rgb(218, 218, 218) !important;
    }
  }
}

.inner{
  padding-top: 1rem;
}