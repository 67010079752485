.shared-designeditor {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    .design-left {
        text-align: left;
    }
    .editTags{
        position:relative;
    }

    .children-section {
        width: 100%;
        margin-top: 1%;
        padding-left: 1%;
        padding-right: 1%;
    }

    #toggle {
        margin-top: 300px;
        background-color: #363636;
        padding: 15px 8px;
        position: fixed;

        p {
            writing-mode: vertical-rl;
            text-orientation: mixed;
            color: #FFFFFF;
        }
    }

    #close {
        width: 0;
        height: 0;
        border-width: 11.5px 14px 11.5px 3px;
        border-color: transparent #FF9100 transparent transparent;
        border-style: solid;
        position: absolute;
        right: 0;
        top: 50%;
    }
}

.detailed-part.preview-view {
    z-index: 2;
    position: relative;
}

.preview-view {
    .detailed-page {
        width: 90%;

        .image-part {
            height: 82vh;
        }
    }
}

.finish-button {
    width: 75%;
    margin: 0 auto;
    text-align: right;
    bottom: 0.5rem;
    right: 12.5rem;
    display: flex;
    justify-content: flex-end;

    .buttons {
        display: flex;
    }

    .disabled-btn {
        padding: 6px 20px;
        width: 90px;
        color: white;
        font-size: 14px;
        border-radius: 7px;
        background-color: rgb(28, 28, 28);
        max-width: 115px;
        position: relative;
        cursor: default;
        opacity: 0.5;
    }

    .finish {
        padding: 6px 20px;
        background: #FC9621;
        border-radius: 5px;
        color: white;
        font-size:12px;
        width: 90px;
        display: inline-block;
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        cursor: pointer;
        color: white;
        border-radius: 7px;
        background-color:rgb(28 28 28);
        max-width: 115px;
        &:hover {
            background-color: rgb(55, 55, 55);
        }

        &.previewbtn {
            background-color: rgb(48, 48, 48);
            color: lightgray;
            margin-right: 10px;
            padding: 6px 5px;
            color: white;
            font-size: 12px;
            background-color: rgb(45, 45, 45);

            &:hover {
                background-color: rgb(55, 55, 55);
            }

            &:before {
                background-color: rgb(39, 39, 39);
                color: white;
            }
        }



    }

    .center-content {
        
        .tags {
            display: flex;
            flex-direction: column;
            align-items: flex-start !important;
            
            >div{
                display: flex;
                width: 100%;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start !important;
                box-sizing: border-box;
                p{
                    margin-right: 30px;
                    margin-left: 5px;
                }
                div{
                    width: 100%;
                   div{
                    width: fit-content;
                    display: inline-block;
                    span{
                        margin-left: 15px;
                    }
                   }
                }
                
                align-items: center;
            }
            span{
                color: rgba(255, 255, 255, 0.648);
                margin-top: -1rem;
                font-size: 13px;
                text-align: left;
                margin-left: 90px;
                margin-bottom: 10px;
            }
        }

    

        .title {
            color: #8D8D8D;
        }

        .name {
            display: inline-flex;
            gap: 20px;
            align-items: center;
            justify-content: center;
            width: 100%;

            p {
                width: 60px;
            }

            button {
                background: #0C0C0C;
                width: 75px;
                height: 30px;
                text-align: center;
                color: #CCCCCC;
                border-radius: 5px;
            }
        }

        input {
            width: 82%;
        }
    }
}

.cancel {
    color: #FBFBFB;
    background: #575757;
    padding: 10px 40px;
    width: 136px;
    border-radius: 5px;
    font-size: 16px;
    display: inline-block;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken(#575757, 8%);
        transition: all .3s;
        z-index: -1;
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }
}

.buttons {
    display: flex;
    gap: 0px;
    width: 100%;
}


.sidebar {
    height: 82%;
    background-color: #2B2B2B;
    color: white;
    width: 150px;
    padding: 20px 15px 10px 15px;
    z-index: 200;
    border-radius: 10px;
    position: absolute;
    left: 0;
    transition: left 1s ease-out;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    overflow: auto;

    .drag-drop {
        padding-top: 10px;
        font-weight: 600;
    }

    .images-design {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
        row-gap: 5px;
        column-gap: 5px;
        margin-bottom: 20px;

        img {
            border-radius: 5px;
            width: 100%;
            cursor: pointer;
        }
    }

    .overlay {
        img {
            border-radius: 5px;
            width: 100%;
            margin-bottom: 8px;
            cursor: pointer;
        }
    }

    .text-img {
        color: #9F9F9F;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 10px;
    }
}

.close {
    left: -200px;
    transition: left 1s ease-out;
}

.bottombuttons-template {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 2rem;
    margin-top: -20px;
    margin-bottom: 2.2rem;
    bottom: -2.25rem;
    z-index: 3;

    button {
        margin: 5px 0;
        border-radius: 5px !important;
    }
}

.finish{
    &.popupbtn{
        background-color: rgb(56, 56, 56) !important;
        margin: 0;
        margin-right: -1rem;
    }
}
.spantxt{
    text-align: left;
    opacity: 0.6;
    font-size: 13px;
    width: 68%;
}
@media screen and (min-height: 722px) {
    .finish-button{
        .finish, .finish.previewbtn, .disabled-btn {
            font-size: 14px;
        }
    }
}

@media screen and (min-height: 750px) {
    .shared-designeditor{
        .children-section{
            // margin-top: 2%;
        }
    }
}
@media screen and (min-width: 1550px) {
    .shared-designeditor{
        .children-section{
            padding-right: 0%;
        }
    }
}
@media screen and (min-width: 2560px) {
    .finish-button {
        position:absolute;
        right: 24%;
        .finish, .finish.previewbtn, .disabled-btn {
            max-width: 180px;
            width: 160px;
            font-size: 18px;
        }

        .finish.previewbtn {
            margin-right: 15px;
        }
    }
}

@media screen and (min-width: 3000px) {
    .finish-button{
        .finish, .finish.previewbtn, .disabled-btn {
            padding: 10px 20px;
            max-width: 260px;
            width: 260px;
            font-size: 38px;
        }

        .finish.previewbtn {
            margin-right: 20px;
        }
    }
    .bottombuttons-template {
        right: 155px;
    }
    .spantxt{
        font-size: 17px;
    }
}

@media screen and (min-width: 4000px) {
    .finish-button{
        .finish, .finish.previewbtn, .disabled-btn {
            padding: 22px;
            width: 380px;
            font-size: 49px;
        }

        .finish.previewbtn {
            margin-right: 30px;
        }
    }
    .bottombuttons-template {
        gap:10px;
    }
}


.input-flex {
    display: flex;
    align-items: flex-start;
    width: 90%;
    justify-content: flex-start;
    margin-left: -2rem;
    p{
        min-width:70px;
        margin-right: 5px;
        text-align: left;
    }
}
.buttons{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    margin-right: 1.3rem;
    margin-top: 2rem;
    position: absolute;
    bottom: 1.7rem;
    right: 1.3rem;
    button{
        opacity: 0.7;
        &:hover{
            opacity: 1;
        }
        &:last-child{
            color: #ec7600;
        }
    }
}