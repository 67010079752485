.inputGroup {
    background-color:  #3b3b3b75;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    align-items: center;
    margin-bottom: 20px;
    button{
      width: 15px;
      height: 15px;
      margin-left: 10px;
      margin-bottom: 5px;
      svg{
        width: 100%;
        height: 100%;
        path{
          fill:#8c8c8caa;
        }
      }
    }
    input {
      background: none !important;
      border: none;
      color: white;
      padding: 12px;
      font-size: 12px;
      width: 100%;
      &::placeholder {
        color: rgba(255, 255, 255, 0.507);
        font-size: 14px;
      }
    }
    svg {
      cursor: pointer;
      &:hover {
        g,
        path {
          fill: white;
        }
      }
    }
  }

  @media screen and (min-height: 900px) {
    .inputGroup{
      input{
        font-size: 14px;
      }
    }
  }
  .blue-mode,.purple-mode,.green-mode{
    .inputGroup input::placeholder{
        color: rgb(255, 255, 255);
      }
  }
  .bright-mode{
    .inputGroup{
      background-color: white;
    }
  }