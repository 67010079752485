.progress-bar {
  width: 100%;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  p {
    font-size: 8px;
    position: fixed;
    margin-top: 18px;
    margin-left: 5px;
    opacity: 0.8;
  }
  .progress-bar-fill {
    background-image: linear-gradient(50deg, #38ad9493, #4763988e, #e46b2085);
  }
}
.reactour__popover,
.reactour__helper {
  background-size: cover;
  background-position: center;
  padding: 25px 35px !important;
  padding-bottom: 15px !important;
  width: 460px !important;
  max-height: max-content;
  height: auto;
  border-radius: 2px;
  background-color: rgba(48, 47, 47, 0.868) !important;
  backdrop-filter: blur(50px);
  z-index: 9999999999 !important;
  img {
    margin-top: 15px;
    max-width: 100%;
    border-radius: 5px;
    object-fit: cover;
    display: block;
  }
  .svgstep {
    display: inline-flex;
    svg {
      width: 18px;
      height: 18px;
      display: inline-flex;
      transform: translateY(5px);
      margin: 0 5px;
      opacity: 0.8;
      g,
      path {
        fill: white;
      }
      circle {
        stroke: white;
      }
    }
  }
  .step-menu {
    background-color: rgba(256, 256, 256, 0.1);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    transform: translateY(7px);
    svg {
      width: 20px;
      height: 20px;
      transform: scale(0.8);
    }
  }
  span {
    display: inline-flex;
    margin: 0px 5px;
    width: 15px;
    height: 10px;
    position: relative;
    svg {
      width: 13px;
    }
    img {
      width: 13px;
      height: 13px;
      margin-top: 0px;
      transform: translateY(2px);
    }
  }
  .docstep {
    width: auto !important;
    height: auto !important;
    height: 20px;
    img {
      width: 70px;
      height: auto;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.324);
      border-radius: 30px;
    }
  }
  h1 {
    font-size: 18px;
    font-weight: bold;
    opacity: 0.9;
  }
  div[dir="ltr"] {
    justify-content: flex-end;
    button {
      padding: 5px 20px;
      font-size: 13px;
      &:last-child {
        background-color: rgba(128, 128, 128, 0.212);
        border-radius: 30px;
        padding: 5px 25px;
        background-image: linear-gradient(
          50deg,
          #38ad9493,
          #4763988e,
          #e46b2085
        );
      }
    }
  }
  span {
    &:first-child {
      background: #2c2c2c;
      width: 100%;
      height: auto !important;
      padding: 0;
      margin: 0;
      top: 0 !important;
      left: 0 !important;
    }
  }
  * {
    color: white !important;
  }
  div {
    &:first-child {
      h1 {
        font-weight: bold;
        opacity: 0.8;
      }
    }
  }
  div[data-tour-elem="controls"] {
    justify-content: flex-end;

    button {
      margin-right: 0;
      &:nth-child(2) {
        background-color: rgba(128, 128, 128, 0.212);
        border-radius: 30px;
        padding: 8px 25px;
        background-image: linear-gradient(
          50deg,
          #38ad9493,
          #4763988e,
          #e46b2085
        );
      }

      span {
        font-size: 15px;
      }
    }
  }
}
.progress-bar-fill {
  height: 100%;
  transition: width 0.2s;
}
.custom-your-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem;
  margin-bottom: 0rem;
  margin-right: 0;

  button {
    padding: 2px 20px;
    font-size: 13px;
    opacity: 0.7;

    &:nth-child(2) {
      background-color: rgba(128, 128, 128, 0.212);
      border-radius: 30px;
      opacity: 1;
      padding: 5px 25px;
      background-image: linear-gradient(50deg, #38ad9493, #4763988e, #e46b2085);
    }
  }
}
.reactour__popover {
  div {
    text-align: left;
    font-size: 13px;
  }
}
.loading__popover {
  position: fixed;
  z-index: 99999;
  background-color: #111111;
  min-width: 430px;
  padding: 22px 30px;
  top: 12px;
  left: auto;
  right: 0px;
  color: white;
  height: 50px;
}
.loading__popover img {
  max-width: 25px;
  margin-left: 20px;
  margin-top: -5px;
}

.panelIcon {
  cursor: pointer;
}

.modal-open {
  .reactour__popover {
    display: none;
  }
}

._3CErh{
  display: flex;
  align-items: center;
  justify-content: center;
  ._1O-y2{
    margin-top: 0 !important;
  }
}