.banner{
    margin-bottom: 4rem;
    button{
        color: white;
        padding: 8px 30px;
        border-radius: 30px;
        position: relative;
        background-color: #362A63;
        backdrop-filter: blur(10px);
        margin-top: 10px;
        svg{
            position: absolute;
            top: -25px;
            right: -10px;
            width: 30px;
        }
    }
    .image{
        min-height: 42vh;
        background-size: cover;
        background-image:url("https://cdn.cf.masterwizr.com/Group_40567_1_1_ovvgo4.png");
        justify-content: center;
        align-items: center;
        height: 100%;
        background-position:bottom;
        display: flex;
        align-items: center;
        padding-top: 5vh;
        p{
            font-size: 18px;
            color: white;
            margin-top: 10px;
            opacity: 0.7;
        }
        h2{
              margin: auto;
              font-size: 4rem;
              font-weight: bolder;
              color: white;
        }
    }
    @media screen and (max-width: 1550px) {
        .image {
            h2 {
                font-size: 3.4rem;
            }
            p {
                font-size: 17px;
            }
        }
    }
}
    .tabs{
        padding: 1rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: -5px;
        z-index: 9999;
        width: max-content;
        margin: 0 45%;

        // background-color: rgb(17, 17, 17);
        color: white;
        // background: linear-gradient(to right, #e57102,#a34f01,#e57102);
        a{
            font-size: 18px;
            padding: 0 20px;
            color: rgba(235, 235, 235, 0.694);
            font-weight: 500;
            border-bottom: 1px solid transparent;
            position: relative;
            z-index: 1;
            &::after{
                content: "";
                position: absolute;
                bottom: -12px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: white;
                z-index: -1;
                transition: transform 0.3s ease-in-out;
                visibility: hidden;
            }
            &:hover{
            color: rgba(214, 214, 214, 0.718);
            }
            &.selected{
                color: white;
                opacity: 1;
                &::after{
                    visibility: visible;
                }

            }
        }
    }

    .backbutton {
        position: fixed;
        z-index: 999999999;
        color: white;
        opacity: .7;
        top: 10px;
        left: 20px;
        font-size: 14px;

        &:hover {
            opacity: 1;
        }
    }

    .sharingInfo {
        display: grid;
        grid-template-columns: .9fr 1fr;
        gap: 100px;

        .infoTitle {
            font: 200 32px/40px Raleway;
        }

        .desc {
            font: 900 18px/22px Raleway;
            margin: 30px 0 !important;
        }

        .infoList {
            color: white;
            list-style: disc;

            li {
                font: 500 16px/22px Raleway;
                margin: 10px 0;
            }
        }

        .subtitle {
            font: 900 18px/22px Raleway;
            margin-top: 20px !important;

            button {
                padding: 0;
                outline: none;
                border: none;
                color: #ec7600 !important;
                background-color: transparent !important;
                margin: 0;
                font: 900 18px/22px Raleway;
            }
        }

        .studioBtn {
            background-color: #ec7600;
            border-radius: 30px !important;
            margin: 20px 0 !important;
            padding: 8px 25px !important;
        }

        img {
            width: 100%;
            height: auto;
        }
    }