.container {
  background-color: #131313;

  .cardsContainer {
    max-width: 80%;
    width: 100%;
    margin: 0 auto;
    padding: 50px 0;

    .card {
      width: 100%;
      padding: 30px 20px;

      .title {
        color: white;
        font: 900 45px/50px Raleway;
        text-align: center;
        margin-bottom: 70px;
      }
    }

    .cardsMapped {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      gap: 40px;
    }
  }

  @media (max-width: 1199px) {
    .cardsContainer {
      max-width: 90%;

      .card {
        .title {
          font: 900 35px/40px Raleway;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .cardsContainer {
      .card {
        .title {
          font: 900 30px/35px Raleway;
        }
      }

      .cardsMapped {
        display: grid;
        grid-template-columns: 1fr;
        gap: 80px;
      }
    }
  }
}

body:global(.purple-mode){
  .container{
    background: rgb(24, 27, 38) !important;
    *{
      color: white;
    }
  }
}
body:global(.green-mode){
  .container{
    background: rgb(18, 28, 25) !important;
    *{
      color: white;
    }
  }
}
body:global(.blue-mode){
  .container{
    background: rgb(1, 37, 59) !important;
    *{
      color: white;
    }
  }
}